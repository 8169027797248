import { Box, styled } from "@mui/material";
import { Fragment, useMemo } from "react";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";

import { useTheme } from "@emotion/react";
import Table from "custom_react_table7";
import { ListingContainer } from "../../styles";
import { parseQueryParams } from "../../utils/queryParams";

export const OptionsContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  wordBreak: "break-all",
  alignItems: "center",
  width: "100%",
});

export const ColorIndicator = styled(Box)({
  width: "15px",
  aspectRatio: 1,
  borderRadius: "50%",
  border: "0.5px solid black",
});

export const OptionsContainerChild = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
});

export const CustomReactTable = ({
  columnData,
  rawData,
  style,
  columnSize,
  count = 10,
  pageSize,
  currentPage,
  onPageNumberChange,
  onChangePageSize,
  disablePagination,
  disableColumnHiding,
  disableSort,
  disableRowSelection = true,
  selectRows = () => {},
  isLoading,
  maxHeight,
  manualSort,
  pagination,
  disableLayout,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathName = location.pathname;
  const pageParams = parseQueryParams(location?.search);
  const theme = useTheme();
  const columns = useMemo(() => columnData, [columnData]);
  const data = useMemo(() => rawData, [rawData]);

  const tableSortValues = (value) => {
    const data = {
      ...pageParams,
      sort: JSON.stringify(value),
    };
    if (value?.length === 0) {
      delete data?.sort;
    }
    return navigate(
      {
        pathName: `${pathName}`,
        search: `?${createSearchParams({ ...data })}`,
      },
      { state: location?.state || null }
    );
  };

  const Layout = disableLayout ? Fragment : ListingContainer;

  return (
    <Layout>
      <Table
        columnData={columns}
        rawData={data}
        selectRows={selectRows}
        columnSize={columnSize ? columnSize : true}
        disableColumnHiding={disableColumnHiding ? disableColumnHiding : false}
        disableRowSelection={disableRowSelection}
        pagination={!pagination}
        disablePagination={disablePagination}
        pageSizes={pageSize}
        currentPage={currentPage}
        onChangePageSize={onChangePageSize}
        onPageNumberChange={onPageNumberChange}
        pageCount={count}
        disableColumnResize={true}
        disableSort={disableSort}
        manualSort={manualSort || false}
        onSort={tableSortValues}
        isLoading={isLoading || false}
        style={{
          table: {
            maxHeight: maxHeight || "calc(100vh - 280px)",
            boxShadow: theme.shadows?.[4],
            "&::-webkit-scrollbar": {
              width: "3px",
              height: "3px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#6E6E6E60",
              borderRadius: 5,
            },
            "&::-webkit-scrollbar-thumb:hover": {
              background: "#555",
            },
            ".tableHead": {
              maxWidth: "100%",
              userSelect: "none",
              color: theme?.palette?.primary?.main,
              // ".tr": {
              //   backgroundColor: "#F8F9F9",
              // },
            },
          },
          mainContainer: {
            maxWidth: "100%",
          },

          ".css1kciki3 .tableHead": {
            backgroundColor: "rgb(0, 107, 189)",
          },
          th: {
            font: "normal normal bold 14px/19px sans-serif !important",
            display: "flex !important",
            height: "55px !important",
            // backgroundColor: "#F8F9F9",
            color: theme?.palette?.text.primary,
            backgroundColor: theme?.palette?.common?.white,
          },
          body: {
            font: "normal normal normal 14px/19px sans-serif !important",
            ".tr": {
              "[data-sticky-td]": {
                paddingBlock: 10,
                // marginBlock: 10,
              },
              // [data-sticky-td]:first-of-type:not([data-sticky-last-left-td])
              // [data-sticky-td]:first-child:not(data-sticky-last-left-td)
              "[data-sticky-td]:first-child:not(data-sticky-last-left-td)": {
                paddingBlock: "1%",
                boxShadow: "none",
              },
            },
          },
          headerText: {
            overflow: "visible",
            userSelect: "none",
            ...(style?.headerText ?? {}),
          },
          tr: {
            display: "flex",
            alignItems: "center",
            height: "auto",
            color: theme.palette.text.primary,
            backgroundColor: theme?.palette?.common?.white,
            boxShadow: "0px 0px 5px 0px rgba(219,216,219,0.67)",

            ".primaryRow": {
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            },
            ".primaryRowData": {
              display: "flex",
              alignItems: "center",
            },
            "[data-sticky-td]": {
              boxShadow: "none",
              zIndex: 2,
              backgroundColor: "white",
            },
          },
          td: {
            padding: 10,
            paddingRight: 30,
            backgroundColor: theme?.palette?.common?.white,
            ".MuiBox-root": {
              textOverflow: "ellipsis",
            },
          },
          loaderContainer: {
            display: "none !important",
          },
          tableHead: {
            backgroundColor: "rgb(0, 107, 189)",
          },
          pagination: {
            color: theme?.palette?.paginationColor?.main,
            font: "normal normal normal 14px/19px sans-serif !important",
            backgroundColor: `${theme?.palette?.common?.white} !important`,
            height: "2.5rem !important",
            boxShadow: "none !important",
          },
          emptyMessage: {
            color: theme.palette.text.primary,
          },

          ...style,
        }}
      />
    </Layout>
  );
};

export default CustomReactTable;
