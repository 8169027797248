import { Grid } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { postApiServices } from "../../api/api";
import { apiPaths } from "../../api/apiPaths";
import WithCondition from "./WithCondition";
import CustomAutoComplete from "./formFields/CustomAutoComplete";

const CustomerSelector = ({
  values,
  setFieldValue,
  handleBlur,
  errors,
  touched,
  xs,
  size = "medium",
  hide,
  inputStyles = {},
  hideClose,
  selectFirst = false,
}) => {
  const userInfo = useSelector((state) => state?.userInfo);
  const isAdmin = useSelector((state) => state?.userInfo?.isAdmin) ?? false;
  const [params] = useSearchParams();
  const editId = params.get("editId");

  const { data: customers } = useQuery({
    queryKey: ["getCustomers"],
    queryFn: () =>
      postApiServices(
        apiPaths.CUSTOMERS
        // getFilter("statusCode", CODES.ACTIVE, isViewMode)
      ),
  });

  useEffect(() => {
    if (!isAdmin && !values?.customerId) {
      setFieldValue("customerId", userInfo?.customerId);
    }
  }, [isAdmin, setFieldValue, userInfo?.customerId, values?.customerId]); //eslint-disable-line

  useEffect(() => {
    if (
      customers?.data?.rows?.length &&
      isAdmin &&
      selectFirst &&
      !userInfo?.customerId
    ) {
      setFieldValue("customerId", customers?.data?.rows?.[0]?.id);
    }
  }, [customers, isAdmin, selectFirst, setFieldValue, userInfo?.customerId]);

  if (hide || !isAdmin) return null;

  return (
    <WithCondition isValid={isAdmin}>
      <Grid xs={xs ?? 6} item>
        <CustomAutoComplete
          label={"Customer *"}
          name='customerId'
          value={values?.customerId}
          customOnchange={(e, v) => {
            setFieldValue("customerId", v?.id);
          }}
          onBlur={handleBlur}
          errors={errors?.customerId}
          touched={touched?.customerId}
          isViewMode={!!editId}
          inputValues={customers?.data?.rows || []}
          labelAccessor={"organizationName"}
          size={size}
          style={inputStyles}
          hideClose={hideClose}
        />
      </Grid>
    </WithCondition>
  );
};

export default CustomerSelector;
