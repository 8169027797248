export const loginInitialValues = {
  mobileNo: "",
  password: "",
};

export const verifyOtpInitialValues = {
  mobileNo: "",
  otp: "",
};

export const setPasswordInitialValues = {
  otp: "",
  password: "",
  confirmPassword: "",
};
