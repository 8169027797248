import { ExpandMore } from "@mui/icons-material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { ScreenHelp, WithCondition } from "components/shared";

export const CustomAutoComplete = ({
  className,
  label,
  onChange,
  onBlur,
  value,
  inputValues = [],
  readOnly,
  name,
  errors,
  touched,
  required,
  accessor,
  fullWidth,
  labelAccessor,
  isReturnObject,
  isViewMode,
  getOptionLabel,
  disabled,
  customOnchange,
  hideClose,
  size,
  help,
  style = {},
  renderOption,
  getOptionDisabled = () => false,
  disableCloseOnSelect = false,
}) => {
  return (
    <>
      <Autocomplete
        className={className}
        label={label}
        name={name}
        size={size || "medium"}
        readOnly={readOnly || isViewMode}
        fullWidth={fullWidth || true}
        options={inputValues || []}
        value={
          inputValues?.find((item) => item?.[accessor || "id"] === value) ||
          null
        }
        disablePortal
        getOptionLabel={(option) =>
          (getOptionLabel && getOptionLabel(option)) ||
          option?.[labelAccessor] ||
          option?.name ||
          option?.label ||
          "No data Found"
        }
        getOptionDisabled={getOptionDisabled}
        renderOption={renderOption || false}
        onChange={
          customOnchange ||
          ((_, v) =>
            onChange(
              name,
              isReturnObject ? v || { id: "" } : v?.[accessor || "id"] || ""
            ))
        }
        popupIcon={<ExpandMore />}
        onBlur={onBlur}
        disabled={Boolean(disabled)}
        disableClearable={!!hideClose}
        disableCloseOnSelect={disableCloseOnSelect}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              {...(inputValues?.length < 5 && {
                onKeyPress: (e) => e.preventDefault(),
              })}
              variant='outlined'
              label={label}
              name={name}
              error={errors && touched}
              helperText={touched && errors}
              fullWidth
              required={required}
              readOnly={readOnly || isViewMode}
              style={style}
              // sx={{ label: { fontSize: "14px" } }}
            />
          );
        }}
      />
      <WithCondition isValid={help}>
        <span>
          <ScreenHelp htmlText={help} isField />
        </span>
      </WithCondition>
    </>
  );
};

export default CustomAutoComplete;
