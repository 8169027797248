import { Popover as MuiPopper, styled } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";

import { getRightSideNavs } from "../../constants/menu.js";

const PopoverComponent = styled(MuiPopper)({
  "& .MuiPaper-root": {
    minWidth: "150px",
    width: "200px",
  },
});

const Titles = styled(Typography)(({ theme }) => ({
  padding: "14px !important",
  cursor: "pointer !important",
  font: "normal normal normal 16px/19px sans-serif !important",
  // color: `${theme?.palette?.popoverColor?.main} !important`,
}));

export const RightMenu = ({ open, anchorEl, handleClose, redirect }) => {
  const isAdmin = useSelector((state) => state?.userInfo?.isAdmin);
  const role = useSelector((state) => state?.userInfo?.role);


  const navItems = getRightSideNavs(isAdmin);

  return (
      <PopoverComponent
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {navItems?.map(
          ({ id, label, routePath, type, render, action }, index) => {
            if (!render)
              return (
                <Titles
                  id={id}
                  key={index}
                  onClick={() => {
                    if (action) action();
                    else redirect(routePath, type);
                  }}
                >
                  {label}
                </Titles>
              );
            return (
              render(role?.code) && (
                <Titles id={id} key={index} onClick={() => redirect(routePath)}>
                  {label}
                </Titles>
              )
            );
          }
        )}
      </PopoverComponent>
  );
};
