import { useTheme } from "@emotion/react";
import { Checkbox, FormLabel } from "@mui/material";
import { ScreenHelp, WithCondition } from "components/shared";

export const CustomCheckBox = ({
  name = "",
  label = "",
  onChange = () => {},
  checked = false,
  isViewMode,
  disabled,
  style = {},
  indeterminate,
  help,
  labelPosition = "right",
  size = "small",
  align,
}) => {
  const theme = useTheme();
  return (
    <FormLabel
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: labelPosition === "right" ? "row" : "row-reverse",
        gap: "10px",
        cursor: "pointer",
        float: align ? align : "",
        ...style,
      }}
    >
      <Checkbox
        size={size}
        id={name}
        name={name}
        sx={{ padding: 0, margin: 0, marginRight: 1 }}
        checked={Boolean(checked)}
        indeterminate={indeterminate}
        disabled={isViewMode || disabled}
        onChange={(e) => {
          e.target.value = e.target.checked;
          onChange(e);
        }}
        inputProps={{ "aria-label": "controlled" }}
        color="primary"
      />

      <FormLabel
        htmlFor={name}
        sx={{
          color: theme.palette.text.secondary,
          cursor: "pointer",
          userSelect: "none",
        }}
      >
        {label}
        <WithCondition isValid={help}>
          <span>
            <ScreenHelp
              htmlText={help}
              isField
              buttonStyle={{ top: 0, ml: "5px" }}
            />
          </span>
        </WithCondition>
      </FormLabel>
    </FormLabel>
  );
};
