/* eslint-disable react-hooks/exhaustive-deps */
import { Suspense, useEffect } from "react";
import { useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { AppBar, CustomLoader, SideBarNavigators } from "components/shared";
import { sideMenusWithRole } from "constants/menu";
import { ROUTE_PATHS } from "routes/routePaths";
import {
  AppContainerLayout,
  AppMainContainer,
  AppMainLayout,
} from "styles/Layouts";

export function Layout() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { isAdmin } = useSelector((state) => state?.userInfo);
  const sideMenus = sideMenusWithRole?.[isAdmin ? "super-admin" : "user"];
  useEffect(() => {
    if (pathname === ROUTE_PATHS.LAYOUT && sideMenus?.[0])
      navigate(sideMenus?.[0]?.navigateTo);
  }, [navigate, pathname, sideMenus]);

  return (
    <AppContainerLayout>
      <AppBar />
      <AppMainContainer>
        <SideBarNavigators menuList={sideMenus} />
        <Suspense fallback={<CustomLoader />}>
          <AppMainLayout>
            <Outlet />
          </AppMainLayout>
        </Suspense>
      </AppMainContainer>
      {/* <SwitchTheme /> */}
    </AppContainerLayout>
  );
}
