import { appApi } from "./config";

// post call
export const postApiServices = (path, value = {}) => {
  return appApi.post(`${path}`, value);
};

// update call
export const postUpdateApiServices = (path, id, value) => {
  return appApi.post(`${path}/${id}`, value);
};

//  get By id
export const getByIdApiServices = (path, id) => {
  return appApi.get(`${path}/${id}`);
};

// update call
export const updateApiServices = (path, id, value) => {
  return appApi.put(`${path}/${id}`, value);
};

export const putApiServices = (path, value) => {
  return appApi.put(`${path}`, value);
};

//get call
export const getApiServices = (path) => {
  return appApi.get(path);
};

export const deleteApiServices = (path, id) => {
  return appApi.delete(`${path}/${id}`);
};

//seed call
export const getSeedService = (path = "") => appApi.get("/seeds/" + path);
