import { useMutation } from "@tanstack/react-query";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Box, Grid, Paper, styled, Typography } from "@mui/material";
import { CustomPasswordField, CustomTextField } from "components/shared";
import { StyledButtonContainer, SubmitButton } from "styles";
import { postApiServices } from "../../../api/api";
import { apiPaths } from "../../../api/apiPaths";
import employee_banner from "../../../assets/employee_management.avif";
import leave_banner from "../../../assets/Leave-Management-System.webp";
import time_banner from "../../../assets/timeImage.jpg";
import { loginInitialValues as initialValues } from "../../../constants/login.js";
import useDispatchActions from "../../../hooks/useDispatchActions.js";
import { ROUTE_PATHS } from "../../../routes/routePaths";
import { getValidValues } from "../../../utils/common.js";
import { setCookie } from "../../../utils/cookies.js";
import { validationSchema } from "../../../validations/login";

const slides = [
  {
    image: employee_banner,
    heading: "Welcome to CRM",
    text: "Manage your employee data efficiently.",
  },
  {
    image: time_banner,
    heading: "Welcome to CRM",
    text: "Track attendance with ease.",
  },
  {
    image: leave_banner,
    heading: "Welcome to CRM",
    text: "Handle all your leave requests seamlessly.",
  },
];

const Container = styled("div")({
  display: "flex",
  height: "100vh",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#E9EAF0",
});

const LoginBox = styled(Paper)({
  display: "flex",
  flexDirection: "row",
  width: "70%",
  height: "70%",
  borderRadius: "15px",
  overflow: "hidden",
});

const FormSection = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "50%",
  padding: "2rem",
  backgroundColor: "#fff",
  zIndex: 1,
});

const LeftSideContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: "32px",
  width: "50%",
  position: "relative",
});

const BrandLogo = styled("img")({
  width: "200px",
  marginBottom: "auto",
  mixBlendMode: "color-burn",
});

const IntroText = styled(Typography)({
  fontSize: "20px",
  textAlign: "center",
});

const SliderContainer = styled(Box)({
  display: "flex",
  width: "100%",
  height: "100%",
  transition: "transform 0.5s ease-in-out",
});

const Slide = styled(Box)({
  minWidth: "100%",
  boxSizing: "border-box",
  padding: "32px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  paddingBottom: "48px",
});

const IndicatorContainer = styled(Box)({
  position: "absolute",
  bottom: "16px",
  display: "flex",
  justifyContent: "center",
  width: "100%",
});

const Indicator = styled(Box)(({ active, theme }) => ({
  width: "10px",
  height: "10px",
  borderRadius: "50%",
  backgroundColor: active ? theme.palette.primary.main : "#bbbbbb",
  margin: "0 4px",
}));

const Login = () => {
  const navigate = useNavigate();
  const { dispatchRole, dispatchUserInfo } = useDispatchActions();

  const { mutate: onSubmit } = useMutation({
    mutationKey: ["login"],
    mutationFn: () => postApiServices(apiPaths.LOGIN, getValidValues(values)),
    onSuccess: ({ data }) => {
      handleRole(data);
    },
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const { values, touched, handleBlur, handleChange, handleSubmit, errors } =
    formik;

  const handleForgetPassword = () => navigate(ROUTE_PATHS?.RESET_PASSWORD);

  const handleRole = (data) => {
    setCookie("roleId", data?.id);
    setCookie("roleCode", data?.code);
    dispatchRole(data?.role);
    dispatchUserInfo(data);
    setCookie("refreshToken", data?.tokens?.refreshToken);
    setCookie("token", data?.tokens?.token);
    setCookie("id", data?.id);
    data?.userType === "admin" && setCookie("appUser", true);
    navigate(ROUTE_PATHS.LAYOUT);
  };

  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 2500);

    return () => clearInterval(interval);
  }, []);

  const handleIndicatorClick = (index) => {
    setCurrentSlide(index);
  };

  return (
    <>
      <Container>
        <LoginBox elevation={10}>
          <FormSection>
            <Typography variant="h4" gutterBottom>
              LOGIN
            </Typography>
            <Typography variant="body1" gutterBottom>
              Welcome to the CRM 😀
            </Typography>
            <Grid container gap={2}>
              <Grid xs={12} item>
                <CustomTextField
                  variant="standard"
                  label={"Mobile Number *"}
                  name="mobileNo"
                  value={values?.mobileNo}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errors={errors?.mobileNo}
                  touched={touched?.mobileNo}
                  maxLength={10}
                  onkeydown={(e) => {
                    if (e.keyCode === 13) {
                      handleSubmit();
                    }
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <CustomPasswordField
                  variant="standard"
                  label="Password *"
                  name="password"
                  showEyeIcon={true}
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errors={errors.password}
                  touched={touched.password}
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                      handleSubmit();
                    }
                  }}
                />
              </Grid>

              {/* <Grid container item xs={12} justifyContent={"flex-end"}>
                <ForgetPassword onClick={handleForgetPassword}>
                  Forget Password
                </ForgetPassword>
              </Grid> */}

              <Grid item xs={12}>
                <StyledButtonContainer sx={{ justifyContent: "center" }}>
                  <SubmitButton
                    color="primary"
                    onClick={handleSubmit}
                    sx={{ padding: "6px 24px !important", margin: 0 }}
                    fullWidth
                  >
                    Login Now
                  </SubmitButton>
                </StyledButtonContainer>
              </Grid>
            </Grid>
          </FormSection>
          <LeftSideContainer>
            <SliderContainer
              style={{ transform: `translateX(-${currentSlide * 100}%)` }}
            >
              {slides.map((slide, index) => (
                <Slide key={index}>
                  <BrandLogo src={slide.image} alt="Brand Logo" />
                  <IntroText variant="h6">{slide.heading}</IntroText>
                  <IntroText variant="body1" sx={{ fontSize: "16px" }}>
                    {slide.text}
                  </IntroText>
                </Slide>
              ))}
            </SliderContainer>
            <IndicatorContainer>
              {slides.map((_, index) => (
                <Indicator
                  key={index}
                  active={index === currentSlide}
                  onClick={() => handleIndicatorClick(index)}
                />
              ))}
            </IndicatorContainer>
          </LeftSideContainer>
        </LoginBox>
      </Container>
    </>
  );
};

export default Login;
