import { Box, Stack, Typography, styled } from "@mui/material";

export const CardListingContainer = styled(Box)({
  width: "90%",
  margin: "auto 4% auto 4%",
  maxHeight: "calc(100vh - 150px)",
  overflow: "auto",
  "&::-webkit-scrollBar": {
    width: 0,
    height: 0,
  },
});

export const CardWrapper = styled(Box)({
  width: "100%",
  height: "auto",
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
  gap: "24px",
  marginBottom: "64px",
  padding: 10,
});

export const CardContainer = styled(Box)({
  width: 260,
  height: 300,
  border: "1px solid #787878",
  borderRadius: 8,
  cursor: "pointer",
});

export const CardBackground = styled(Box)({
  backgroundColor: "#A0A0A0",
  width: "auto",
  height: 110,
  borderRadius: 4,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
});

export const CardImageWrapper = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  marginTop: "-60px",
});

export const CardImage = styled("img")({
  width: 120,
  height: 120,
  borderRadius: "50%",
});

export const ProfileDetailsWrapper = styled(Box)({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  width: "100%",
  marginTop: 3,
  padding: 10,
});

export const ProfileName = styled(Typography)(({ theme }) => ({
  fontSize: "1.3rem",
  textAlign: "center",
  fontWeight: "500",
  textTransform: "capitalize",
  padding: "auto 5",
  color: theme.palette.text.primary,
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  overflow: "hidden",
  maxWidth: 240,
}));

export const ProfileDetail = styled(Typography)(({ theme }) => ({
  width: 240,
  height: 32,
  fontSize: "1rem",
  textAlign: "center",
  fontStyle: "italic",
  "-webkit-line-clamp": "2",
  "-webkit-box-orient": "vertical",
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  verticalAlign: "middle",
  wordWrap: "break-word",
  color: theme.palette.text.primary,
}));

export const PaginationWrapper = styled(Stack)(({ theme }) => ({
  width: "100%",
  justifyContent: "center",
  alignItems: "center",
  position: "absolute",
  bottom: 0,
  left: 0,
  background: theme.palette.background.default,
  padding: "12px 0",
}));

export const StyledImage = styled("img")({
  width: 220,
  height: 250,
  objectFit: "contain",
});

export const DetailSection = styled(Box)({
  display: "flex",
});

export const Title = styled(Typography)({
  width: 180,
  display: "flex",
  justifyContent: "space-between",
  marginRight: 16,
  fontWeight: 700,
});
