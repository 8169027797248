export const filterStringSeeds = [
  {
    id: "eq",
    name: "Equal",
  },
  {
    id: "ne",
    name: "Non Equal",
  },
  {
    id: "beginsWith",
    name: "Begins With",
  },
  {
    id: "endsWith",
    name: "Ends With",
  },
];
