import { createTheme } from "@mui/material/styles";
const oldTheme = createTheme();

export const theme = createTheme({
  palette: {
    primary: {
      main: "#112D4E",
      light: "#3F72AF",
    },
    secondaryAppColor: {
      main: "#A2BEFB",
    },
    paginationColor: {
      main: "#000000DE",
    },
    territory: {
      main: "#9e9e9e",
    },
    textColor: {
      main: "#fff",
    },
    checkboxColor: {
      main: "#00000090",
    },
    profileBg: {
      main: "#f3f3f3",
    },
    background: {
      lightGrey: "#dddddd",
      default: "#DBE2EF",
      paper: "#F9F7F7",
      active: "#173d69",
    },
  },
  components: {
    MuiSvgIcon: {
      styleOverrides: {
        root: ({ ownerState }) => {
          return {
            color:
              ownerState?.color === "inherit" ? "#0000006" : ownerState?.color,
          };
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          position: "relative",
        },
      },
    },
  },
});
export const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#006BBD",
    },
    secondaryAppColor: {
      main: "#A2BEFB",
    },
    paginationColor: {
      main: "#000000DE",
    },
    territory: {
      main: "#9e9e9e",
    },
    textColor: {
      main: "#fff",
    },
    checkboxColor: {
      main: "#00000090",
    },
    profileBg: {
      main: "#f3f3f3",
    },
    background: { lightGrey: "#dddddd" },
  },
  components: {
    MuiSvgIcon: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          color:
            ownerState?.color === "inherit"
              ? oldTheme.palette.grey[200]
              : ownerState?.color,
        }),
      },
    },
  },
});
