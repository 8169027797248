import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";
import { getDateObject } from "../../../utils/dateAndTime";
import ScreenHelp from "../ScreenHelp";
import WithCondition from "../WithCondition";

export const CustomTimePicker = ({
  label,
  onChange,
  value,
  name,
  isViewMode,
  maxTime,
  minTime,
  className,
  disabled,
  touched,
  onBlur,
  errors,
  customHelpertext,
  setTouched,
  views,
  hours_24,
  help,
}) => (
  <>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <TimePicker
        ampm={!hours_24}
        ampmInClock
        views={views || ["hours", "minutes"]}
        className={className}
        label={label}
        name={name}
        readOnly={Boolean(isViewMode)}
        disabled={disabled}
        onBlur={onBlur}
        value={
          value
            ? typeof value === "string"
              ? dayjs(getDateObject({ time: value }))
              : dayjs(new Date(value))
            : null
        }
        fullWidth
        onChange={onChange}
        minTime={minTime ? new Date(minTime) : null}
        maxTime={maxTime ? new Date(maxTime) : null}
        autoFocus={Boolean(value)}
        error={Boolean(customHelpertext || (touched && errors))}
        helperText={customHelpertext || (touched && errors ? errors : "")}
        closeOnSelect
        sx={{ width: "100%" }}
        slotProps={{
          textField: {
            onBlur: (e) => {
              !touched?.lastDonatedDate && setTouched(name, e.type === "blur");
            },
            error: !!touched && !!errors,
            helperText: !!touched && errors,
          },
        }}
      />
    </LocalizationProvider>
    <WithCondition isValid={help}>
      <span>
        <ScreenHelp
          htmlText={help}
          isField
          buttonStyle={{ top: 0, bottom: -16 }}
        />
      </span>
    </WithCondition>
  </>
);
