import { HelpOutline } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import { useState } from "react";
import WithModal from "./WithModal";

const Help = ({
  detail = "",
  htmlText = "",
  style = {},
  buttonStyle = {},
  iconStyle,
}) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Box
        sx={{
          width: "100%",
          textAlign: "end",
          marginLeft: "auto",
          height: 0,
          mt: -2,
          mr: -4.5,
          float: "right",
          ...style,
        }}
      >
        <IconButton onClick={() => setOpen(true)} sx={buttonStyle}>
          <HelpOutline sx={iconStyle} />
        </IconButton>
      </Box>
      <WithModal
        open={open}
        title="Help"
        onClose={() => setOpen(false)}
        onConfirm={() => setOpen(false)}
        alignButtons="flex-end"
        disableClear
        containerStyle={{ justifyContent: "flex-start" }}
      >
        {htmlText ? (
          <div dangerouslySetInnerHTML={{ __html: htmlText }}></div>
        ) : (
          detail
        )}
      </WithModal>
    </>
  );
};

export default Help;
