import { WEEK_DAYS } from "constants";
import { getApiServices } from "../api/api";

const now = new Date();

export const getDateTime = (timeValue = "", date) => {
  const timeVal = timeValue || "";
  if (!timeVal) return "";
  const currentDate = date ? new Date(date) : new Date();
  const [hours, minutes, seconds] = timeVal.split(":").map(Number);
  currentDate.setHours(hours);
  currentDate.setMinutes(minutes);
  currentDate.setSeconds(seconds);

  return currentDate;
};

export const getMinimumAgeDate = (value) => {
  const now = new Date();
  return new Date(
    now.getFullYear() - parseInt(value),
    now.getMonth(),
    now.getDate()
  );
};
export function convertToDateObject(dateString) {
  if (!dateString) return "";
  const parts = dateString.split("-");
  const day = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10) - 1;
  const year = parseInt(parts[2], 10);
  return new Date(year, month, day);
}

export const getDateObject = ({ date, time, localDate }) => {
  const dateValue = time ? new Date(getDateTime(time)) : new Date(date);
  const d = localDate ? convertToDateObject(localDate) : dateValue;

  if (!(date || time || localDate) || d.toString() === "Invalid Date")
    return "";
  return d;
};

export function formatDate({ date, format, time, localDate }) {
  const dateValue = time ? new Date(getDateTime(time)) : new Date(date);
  const d = localDate ? convertToDateObject(localDate) : dateValue;
  const month = (d.getMonth() + 1).toString().padStart(2, "0");
  const day = d.getDate().toString().padStart(2, "0");
  const year = d.getFullYear().toString();
  const hours = d.getHours().toString().padStart(2, "0");
  const minutes = d.getMinutes().toString().padStart(2, "0");
  const seconds = d.getSeconds().toString().padStart(2, "0");

  if (!(date || time || localDate) || d.toString() === "Invalid Date")
    return "";
  const options = { year: "numeric", month: "short", day: "2-digit" };
  const dayOfWeek = { month: "short", day: "2-digit" };
  const stringFormat = new Intl.DateTimeFormat("en-US", options).format(
    dateValue
  );
  const dayOfWeekFormat = new Intl.DateTimeFormat("en-US", dayOfWeek).format(
    dateValue
  );

  switch (format) {
    case "YYYY-MM-DD":
      return `${year}-${month}-${day}`;
    case "DD-MM-YYYY":
      return `${day}-${month}-${year}`;
    case "HH:MM:SS":
      return `${hours}:${minutes}:${seconds}`;
    case "dateTime":
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    case "MMM-DD-YYYY":
      return stringFormat;
    case "MMM-DD":
      return dayOfWeekFormat;
    default:
      return `${year}-${month}-${day}`;
  }
}

export const minMaxAge = ({ maxYear = 100, minYear = 18 } = {}) => {
  const minDate = new Date().setFullYear(new Date().getFullYear() - minYear);
  const maxDate = new Date().setFullYear(new Date().getFullYear() - maxYear);
  return { min: new Date(minDate), max: new Date(maxDate) };
};

export const yesterday = new Date(now);
yesterday.setDate(now.getDate() - 1);

export function timeToMinutes(timeString) {
  const timeParts = timeString.split(":");

  const hours = parseInt(timeParts[0]);
  const minutes = parseInt(timeParts[1]);
  const seconds = parseInt(timeParts[2]);

  const totalMinutes = hours * 60 + minutes + seconds / 60;

  return totalMinutes;
}

export function minutesToTime(minutes) {
  const hours = Math.floor(minutes / 60);

  const remainingMinutes = minutes % 60;

  const seconds = Math.round(
    (remainingMinutes - Math.floor(remainingMinutes)) * 60
  );

  const formattedHours = String(hours).padStart(2, "0");
  const formattedMinutes = String(Math.floor(remainingMinutes)).padStart(
    2,
    "0"
  );
  const formattedSeconds = String(seconds).padStart(2, "0");
  const timeString =
    formattedHours + ":" + formattedMinutes + ":" + formattedSeconds;

  return timeString;
}

export const getCurrentDate = async () => {
  try {
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const response = await getApiServices(
      "https://worldtimeapi.org/api/timezone/" + tz
    );
    const resObject = response.json();

    return resObject?.datetime;
  } catch (error) {}
};

export function getMonthName(monthNumber) {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return monthNames[parseInt(monthNumber) - 1] || "";
}
export const getMonthFirstDate = (dateValue = new Date()) => {
  const oldDate = new Date(dateValue);
  const resultDate = oldDate.setDate(1);
  return new Date(resultDate);
};

export const getMonthLastDate = (dateValue = new Date()) => {
  const lastDay = new Date(dateValue);
  lastDay.setDate(1); // Set day to 1 to get the last day of the previous month
  lastDay.setMonth(lastDay.getMonth() + 1); // Move to the next month
  lastDay.setDate(0); // Set day to 0 to get the last day of the current month
  const resultDate = new Date(lastDay);
  return resultDate;
};

export const getWeekFirstDate = (dateValue = new Date()) => {
  const firstDate = new Date(dateValue);
  const dayOfWeek = firstDate.getDay();
  const diff = firstDate.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1); // Adjust for Sunday
  firstDate.setDate(diff);
  return firstDate;
};

export const getWeekLastDate = (dateValue = new Date()) => {
  const lastDate = new Date(dateValue);
  const dayOfWeek = lastDate.getDay();
  const diff = lastDate.getDate() + (dayOfWeek === 0 ? 0 : 7 - dayOfWeek); // Adjust for Sunday
  lastDate.setDate(diff);
  return lastDate;
};

export const getDayName = (inputDate) => {
  const date = new Date(inputDate);
  return WEEK_DAYS[date?.getDay()];
};

const curDate = new Date();
export const today = formatDate({ date: new Date() });
export const tomorrow = formatDate({
  date: curDate.setDate(curDate.getDate() + 1),
});

export const getDateFromNow = (days) =>
  parseInt(days) > 0
    ? new Date(Date.now() + parseInt(days) * 24 * 60 * 60 * 1000)
    : new Date();

export const daysBetweenDates = (date1, date2) => {
  let timeDiff = date1.getTime() - date2.getTime();

  let oneDay = 1000 * 60 * 60 * 24;

  let diffDays = Math.ceil(timeDiff / oneDay);

  return diffDays;
};

export function excelSerialToJSDate(serial = new Date()) {
  const excelBaseDate = new Date(Date.UTC(1899, 11, 30)); // Excel's base date in UTC
  const msPerDay = 24 * 60 * 60 * 1000;
  const dateInMs = serial * msPerDay;
  const jsDateUTC = new Date(excelBaseDate?.getTime() + dateInMs);
  const localDateString = jsDateUTC?.toISOString();
  return localDateString;
}
