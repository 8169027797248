import {
  Add,
  AlignHorizontalLeft,
  AlignHorizontalRight,
  ArrowBack,
  Cancel,
  Close,
  Download,
  DragHandle,
} from "@mui/icons-material";
import {
  Box,
  Chip,
  IconButton,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";

import queryString from "query-string";
import { useCallback, useMemo } from "react";
import { NewButton, SideBarButton } from "../../styles";
import { CustomSearchField } from "./CustomSearchField";
import { FilterModal } from "./FilterModal";
import WithCondition from "./WithCondition";

const filterIcons = {
  eq: <DragHandle />,
  ne: <Close />,
  beginsWith: <AlignHorizontalLeft />,
  endsWith: <AlignHorizontalRight />,
};
const filterTypes = {
  eq: "Equals to",
  ne: "Not equals to",
  beginsWith: "begins with",
  endsWith: "ends with",
};

const Container = styled(Box)(({ theme }) => ({
  position: "relative",
  // height: 75,
  // width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  marginInline: "25px",
  marginBlock: 12,
  backgroundColor: "inherit",
}));

const ContainerAlign = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const Title = styled(Typography)(({ theme }) => ({
  color: theme?.palette?.primary?.main,
  textTransform: "uppercase",
  fontFamily: "Lato",
  fontWeight: "700",
  fontSize: "1.1rem",
  alignSelf: "left",
  marginRight: "auto",
}));

const IconsContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const BackIcon = styled(IconButton)(({ theme }) => ({
  color: theme?.palette?.primary?.main,
  fontSize: 22,
}));

export const ListTopBar = ({
  label,
  disableSearchField,
  disableFilter,
  disableNewForm,
  newFormPath,
  listPath,
  additionalComponent,
  addPosition = "middle",
  filterFields,
  filterFieldInitial,
  newButtonLabel,
  onDownload,
  showFilterApplied,
  currentFilters,
  navigateOnBack,
  onAddPress,
  hasSideBarOpen = false,
  isSideBarOpen,
  showAddBtn = false,
  showCount,
  count,
}) => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const searchParam = useMemo(() => new URLSearchParams(search), [search]);
  const isFilterApplied = searchParam?.get("filter");
  const pageParams = queryString.parse(search);

  const modifications = useMemo(
    () =>
      currentFilters?.map((item) => ({
        ...item,
        label: filterFields?.find((field) => field?.fieldName === item?.field)
          ?.label,
      })),
    [currentFilters, filterFields]
  );

  const filterdata = useMemo(
    () =>
      searchParam?.get("filter") ? JSON.parse(searchParam?.get("filter")) : [],
    [searchParam]
  );

  const handleClearFilter = useCallback(
    (item) => {
      const filterRemoved = filterdata?.filter(
        (field) => field?.field !== item?.field
      );
      if (!filterRemoved?.length) delete pageParams.filter;

      navigate({
        pathName: listPath,
        search: `?${createSearchParams({
          ...pageParams,
          ...(filterRemoved?.length
            ? { filter: JSON.stringify([...filterRemoved]) }
            : {}),
          currentPage: 1,
        })}`,
      });
    },
    [filterdata, listPath, navigate, pageParams]
  );

  return (
    <Container>
      <ContainerAlign>
        <WithCondition isValid={!!navigateOnBack}>
          <BackIcon
            onClick={() => {
              // if (handleNavigate) handleNavigate();
              navigate(navigateOnBack ?? -1);
            }}
          >
            <ArrowBack color="primary" />
          </BackIcon>
        </WithCondition>
        <Title variant="h6">{label}</Title>
        <IconsContainer>
          <WithCondition isValid={showCount}>
            <Typography fontWeight={"bold"} mr={2}>
              Total : {count}
            </Typography>
          </WithCondition>
          <WithCondition
            isValid={addPosition === "start" && additionalComponent}
          >
            {additionalComponent}
          </WithCondition>
          {!disableSearchField && <CustomSearchField />}
          <WithCondition
            isValid={addPosition === "middle" && additionalComponent}
          >
            {additionalComponent}
          </WithCondition>
          <WithCondition isValid={!disableFilter}>
            <FilterModal
              listPath={listPath}
              filterFields={filterFields}
              filterFieldInitial={filterFieldInitial}
            />
          </WithCondition>
          <WithCondition
            isValid={(!disableNewForm && newFormPath) || showAddBtn}
          >
            <WithCondition isValid={hasSideBarOpen}>
              <SideBarButton
                disabled={isSideBarOpen}
                onClick={() =>
                  onAddPress ? onAddPress() : navigate(newFormPath)
                }
              >
                <Add />
              </SideBarButton>
            </WithCondition>
            <WithCondition isValid={!hasSideBarOpen}>
              <NewButton
                onClick={() =>
                  onAddPress ? onAddPress() : navigate(newFormPath)
                }
              >
                {newButtonLabel ? newButtonLabel : <Add />}
              </NewButton>
            </WithCondition>
          </WithCondition>
          <WithCondition isValid={!!onDownload}>
            <NewButton onClick={onDownload}>
              <Download />
            </NewButton>
          </WithCondition>
          <WithCondition
            isValid={addPosition === "last" && additionalComponent}
          >
            {additionalComponent}
          </WithCondition>
        </IconsContainer>
      </ContainerAlign>

      <WithCondition isValid={!!showFilterApplied && !!isFilterApplied}>
        <Box
          sx={{
            display: "-webkit-box",
            width: "100%",
            maxWidth: "100%",
            overflow: "auto",
            overflowY: "hidden",
            justifyContent: "flex-end",
            alignItems: "center",
            paddingBlock: "5px",
            mt: 1,
            "&::-webkit-scrollbar": {
              width: "5px",
              height: "5px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#6E6E6E60",
              borderRadius: 5,
            },
            "&::-webkit-scrollbar-thumb:hover": {
              background: "#555",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "#F5F5F5",
            },
          }}
        >
          <Typography fontSize={12} fontStyle="italic" sx={{ mr: 2 }}>
            Filters applied :
          </Typography>
          {modifications?.map((item) => (
            <Box sx={{ justifyContent: "flex-end" }}>
              <Chip
                label={
                  <Typography sx={{ fontSize: "10px" }}>
                    {item?.label}
                    <Tooltip title={filterTypes?.[item?.type]}>
                      <IconButton
                        sx={{
                          padding: 0,
                          ".MuiSvgIcon-root": {
                            fontSize: "0.9rem",
                            paddingBottom: "3px",
                          },
                        }}
                      >
                        {filterIcons?.[item?.type]}
                      </IconButton>
                    </Tooltip>
                    {item?.value}
                  </Typography>
                }
                fontSize={12}
                size="small"
                sx={{ ml: "5px" }}
                deleteIcon={<Cancel />}
                onDelete={() => {
                  handleClearFilter(item);
                }}
              />
            </Box>
          ))}
        </Box>
      </WithCondition>
    </Container>
  );
};
