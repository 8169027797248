import { useDispatch } from "react-redux";
import {
  notifyUser,
  setCurrentMode,
  setIsAdmin,
  setPortal,
  setRole,
  setSeed,
  setUserInfo,
  setProfileUpdate
} from "../redux/slice";

const useDispatchActions = () => {
  const dispatch = useDispatch();

  const dispatchUserInfo = (payload) => {
    dispatch(setUserInfo(payload));
  };
  const dispatchSeeds = (payload) => {
    dispatch(setSeed(payload));
  };
  const dispatchPortal = (payload) => {
    dispatch(setPortal(payload));
  };
  const dispatchIsAdmin = (payload) => {
    dispatch(setIsAdmin(payload));
  };
  const dispatchRole = (payload) => {
    dispatch(setRole(payload));
  };
  const notifySuccess = (message) => {
    dispatch(notifyUser([message, "success"]));
  };
  const notifyError = (message) => {
    dispatch(notifyUser([message, "error"]));
  };
  const dispatchPreferredMode = (mode) => {
    dispatch(setCurrentMode(mode));
  };
  const updateProfile = (payload) => {
    dispatch(setProfileUpdate(payload))
  }

  return {
    dispatchUserInfo,
    dispatchSeeds,
    dispatchPortal,
    dispatchIsAdmin,
    dispatchRole,
    notifySuccess,
    notifyError,
    dispatchPreferredMode,
    updateProfile
  };
};

export default useDispatchActions;
