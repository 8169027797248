import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

export const CustomLoader = () => (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "fixed",
      width: "100vw",
      height: "100vh",
      left: "0px",
      top: "0px",
      zIndex: 99,
    }}
  >
    <CircularProgress />
  </Box>
);
