import { Box, styled } from "@mui/material";

export const ListingContainer = styled(Box)(({ theme }) => {
  return {
    // width: "92.5%",
    margin: "auto 25px auto 25px",
    maxHeight: "calc(100vh - 150px)",
    overflow: "auto",
    // boxShadow: theme.shadows?.[2],
  };
});

export const RadioContainer = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "center",
});

export const SideBarListContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  overflow: "hidden",
}));

export const ListWrapper = styled(Box)(({ theme }) => ({
  transition: "0.5s",
}));

export const SideBarWrapper = styled(Box)(({ theme, open }) => ({
  transition: "0.5s",
  width: "40%",
  marginRight: open ? "0px" : "-900px",
  zIndex: 2,
}));

export const SideBarBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme?.palette?.background?.paper,
  height: "calc(100vh - 50px)",
  overflowY: "scroll",
  maxHeight: "100%",
  "&::-webkit-scrollbar": {
    width: "5px",
    height: "5px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#6E6E6E60",
    borderRadius: 5,
  },
  "&::-webkit-scrollbar-thumb:hover": {
    background: "#555",
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "#F5F5F5",
  },
}));

export const SideBarHeaderContainer = styled(Box)(({ theme }) => ({
  padding: "8px 16px",
  borderBottom: `3px solid ${theme?.palette?.primary?.main}`,
}));

export const SideBarBodyContainer = styled(Box)(({ theme }) => ({
  padding: "24px 16px",
  // overflowY: "scroll",
}));
