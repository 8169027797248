import { createSlice } from "@reduxjs/toolkit";

import { getCookie } from "../utils/cookies";
import { makeNotification } from "./actions";

const initialState = {
  snackBar: null,
  isLoading: false,
  userInfo: {},
  seeds: {},
  portal: "",
  isAdmin: false,
  role: {},
  currentMode: getCookie("mode") === "dark" ? "dark" : "light",
};
const { reducer, actions } = createSlice({
  name: "app",
  initialState,
  reducers: {
    notifyUser: (state, action) => {
      state.snackBar = makeNotification(action.payload);
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setUserInfo: (state, action) => {
      state.userInfo = action?.payload;
    },
    setSeed: (state, action) => {
      if (action.payload === "clear") {
        state.seeds = {};
      } else {
        state.seeds = { ...state.seeds, ...action.payload };
      }
    },
    setPortal: (state, action) => {
      state.portal = action?.payload;
    },
    setIsAdmin: (state, action) => {
      state.isAdmin = action.payload;
    },
    setRole: (state, action) => {
      state.role = action.payload;
    },
    setCurrentMode: (state, action) => {
      state.currentMode = action?.payload === "dark" ? "dark" : "light";
    },
    setProfileUpdate: (state, action) => {
      state.userInfo.firstName = action?.payload?.firstName
      state.userInfo.lastName =  action?.payload?.lastName
      state.userInfo.emailId =  action?.payload?.emailId
      state.userInfo.mobileNo =  action?.payload?.mobileNo
    }
  },
});

export default reducer;
export const {
  notifyUser,
  setIsLoading,
  setUserInfo,
  setSeed,
  setPortal,
  setIsAdmin,
  setRole,
  setCurrentMode,
  setProfileUpdate
} = actions;
