import { Close, FilterList } from "@mui/icons-material";
import { Grid, Modal, Typography, useTheme } from "@mui/material";
import { useFormik } from "formik";
import queryString from "query-string";
import { useEffect, useState } from "react";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";

import { CustomSelectField, CustomTextField } from ".";
import { filterStringSeeds } from "../../constants/filterSeedTypes";
import {
  APPLY,
  CANCEL,
  CLEAR_FILTER,
  PALETTE_KEYS,
} from "../../constants/index.js";
import useTableCustomHooks from "../../hooks/useTableCustomHooks.js";
import {
  CancelButton,
  FilterButtonModal,
  FilterContainerStyle,
  FilterFormStyle,
  FilterIconButton,
  FilterTitle,
  SubmitButton,
} from "../../styles";

export const FilterModal = ({ listPath, filterFields, filterFieldInitial }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const pageParams = queryString.parse(location?.search);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const theme = useTheme();
  const { tableReRenderActions } = useTableCustomHooks(location?.pathname);
  const { filterdata } = tableReRenderActions();

  const onSubmit = (data) => {
    let getFilterValues = filterFields.map((item) => ({
      type: data[item?.queryName],
      field: item?.fieldName,
      value: data?.[item?.fieldName]?.trim(),
    }));

    let filterArray = getFilterValues.filter((item) => item.type && item.value);

    filterArray.length !== 0 &&
      navigate({
        pathName: listPath,
        search: `?${createSearchParams({
          ...pageParams,
          filter: JSON.stringify([...filterArray]),
          currentPage: 1,
        })}`,
      });

    handleClose();
  };

  const formik = useFormik({
    initialValues: filterFieldInitial,
    onSubmit,
  });

  const handleClearFilter = () => {
    const data = {
      ...pageParams,
    };
    delete data?.filter;

    navigate({
      pathName: `${listPath}`,
      search: `?${createSearchParams({
        ...data,
        currentPage: 1,
      })}`,
    });
    resetForm();
  };

  const { values, handleChange, resetForm, setFieldValue } = formik;

  useEffect(() => {
    filterFields?.map((item) => {
      const filterValue = filterdata?.find(
        (filterItem) => filterItem?.field === item?.fieldName
      );
      setFieldValue(item?.fieldName, filterValue?.value);
      setFieldValue(item?.queryName, filterValue?.type);
      return "";
    });
  }, [open]); //eslint-disable-line

  return (
    <>
      <FilterIconButton onClick={handleOpen}>
        <FilterList sx={{ fontSize: 24 }} />
      </FilterIconButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <FilterContainerStyle>
          <FilterTitle>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{ color: theme?.palette?.background?.default }}
            >
              Filter
            </Typography>
            <Close onClick={handleClose} style={{ cursor: "pointer" }} />
          </FilterTitle>

          <form onSubmit={formik.handleSubmit}>
            <FilterFormStyle>
              {filterFields?.map((item, index) => {
                return (
                  <Grid
                    container
                    columnSpacing={3}
                    rowSpacing={2.5}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      margin: 0,
                    }}
                    key={index}
                  >
                    <Grid item xs={3}>
                      <Typography sx={{ color: PALETTE_KEYS.text.primary }}>
                        {item?.label}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <CustomSelectField
                        label={"Query"}
                        inputValues={
                          item.querySeeds
                            ? item.querySeeds
                            : filterStringSeeds || []
                        }
                        name={item?.queryName}
                        onChange={handleChange}
                        value={values?.[item?.queryName] || ""}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <CustomTextField
                        label={"Value"}
                        name={item.fieldName}
                        onChange={handleChange}
                        value={values[item.fieldName] || ""}
                      />
                    </Grid>
                  </Grid>
                );
              })}
            </FilterFormStyle>
            <FilterButtonModal>
              <CancelButton
                sx={{ px: 3 }}
                onClick={handleClose}
                style={{ cursor: "pointer" }}
              >
                {CANCEL}
              </CancelButton>
              <CancelButton
                sx={{ px: 3 }}
                onClick={handleClearFilter}
                style={{ cursor: "pointer" }}
              >
                {CLEAR_FILTER}
              </CancelButton>
              <SubmitButton variant="contained" type="submit" sx={{ px: 3 }}>
                {APPLY}
              </SubmitButton>
            </FilterButtonModal>
          </form>
        </FilterContainerStyle>
      </Modal>
    </>
  );
};
