import { Grid } from "@mui/material";

import { CANCEL, SKIP, SUBMIT, UPDATE } from "../../constants/index.js";
import {
  CancelButton,
  StyledButtonContainer,
  SubmitButton,
} from "../../styles";
import WithCondition from "./WithCondition.js";

const FormActions = ({
  isViewMode,
  disableCancel,
  handleOnReset,
  disableSubmit,
  handleSubmit,
  submitLabel = SUBMIT,
  resetLabel = CANCEL,
  isUpdate,
  handleSkip,
  skipLabel = SKIP,
  disableSkip,
}) => {
  return (
    <Grid item xs={12}>
      <WithCondition isValid={!isViewMode}>
        <StyledButtonContainer>
          <WithCondition isValid={!disableCancel}>
            <CancelButton variant="outlined" onClick={handleOnReset}>
              {resetLabel}
            </CancelButton>
          </WithCondition>
          <WithCondition isValid={!disableSkip && handleSkip}>
            <CancelButton variant="outlined" onClick={handleSkip}>
              {skipLabel}
            </CancelButton>
          </WithCondition>
          <WithCondition isValid={!disableSubmit}>
            <SubmitButton
              variant="outlined"
              disableElevation
              onClick={handleSubmit}
            >
              {isUpdate ? UPDATE : submitLabel}
            </SubmitButton>
          </WithCondition>
        </StyledButtonContainer>
      </WithCondition>
    </Grid>
  );
};

export default FormActions;
