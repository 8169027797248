import styled from "@emotion/styled";
import { Done } from "@mui/icons-material";
import { Box, Step, StepLabel, Stepper } from "@mui/material";

const StepperContainer = styled(Box)(({ isHorizontal, theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: isHorizontal ? "block" : "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  border: "1px solid #707070",
  borderWidth: isHorizontal ? 0 : 1,
  padding: isHorizontal ? "0 0 40px" : "0 20px",
  width: "100%",
  overflowY: "scroll",
  "::-webkit-scrollbar": {
    display: "none",
  },
}));

const StyledStepper = styled(Stepper)(({ theme, isHorizontal }) => {
  return {
    maxHeight: "100%",
    boxSizing: "border-box",
    padding: "20px 0",
    width: "100%",
    cursor: "pointer",
    overflowX: "visible",
    paddingBottom: 25,
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    ".MuiStepConnector-root": {
      marginLeft: isHorizontal ? "0" : "17px",
    },
    "MuiStepLabel-label.Mui-completed": {
      color: theme.palette.primary.main,
      fontWeight: "900 ",
    },
    "& .Mui-active": {
      "& .MuiStepConnector-line": {
        borderColor: theme.palette.primary.main,
        borderWidth: "2px",
      },
    },
    "& .MuiStepConnector-line": {
      borderWidth: "2px",
      borderColor: theme.palette.primary.main,
      transform: isHorizontal
        ? "translateX(16px) translateY(3px)"
        : "translateX(3px)",
      minHeight: isHorizontal ? "0" : "28px",
    },

    ".MuiStepLabel-iconContainer": {
      padding: "0 !important",
      border: `1px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
      borderRadius: "60px",
      height: "36px",
      width: "36px",
      background: theme.palette.background.default,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    ".MuiStepLabel-iconContainer.Mui-active": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.background.default,
    },
    ".MuiStepLabel-iconContainer.Mui-completed": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.background.default,
    },
    ".MuiStepLabel-root.MuiStepLabel-horizontal": {
      display: "block !important",
    },
    ".MuiStep-root.MuiStep-horizontal": {
      width: "10px !important",
      padding: "0 13px",
    },
    ".MuiStepLabel-root": {
      padding: "2px",
      height: "30px",
    },
  };
});

const StyledStepLabel = styled(StepLabel)(({ theme, isHorizontal }) => ({
  "& .MuiStepLabel-label ": {
    color: theme.palette.primary.main,
    fontWeight: "700",
    fontSize: "14px",
    width: "max-content",
    paddingLeft: isHorizontal ? "0" : "10px",
  },

  ".MuiStepLabel-label.Mui-active": {
    color: theme.palette.primary.main,
    fontWeight: "700 ",
    paddingLeft: isHorizontal ? "0" : "10px",
  },
  "& .Mui-disabled": {
    color: theme.palette.primary.main,
    fontWeight: "900 ",
  },
  ".MuiStepLabel-label.Mui-completed": {
    color: theme.palette.primary.main,
    fontWeight: "900 ",
  },
}));

const CustomStepper = ({
  activeStep,
  steps,
  onChange,
  isLinear,
  isHorizontal,
}) => (
  <StepperContainer isHorizontal={isHorizontal}>
    <StyledStepper
      activeStep={activeStep}
      orientation={isHorizontal ? "horizontal" : "vertical"}
      nonLinear={!isLinear}
      isHorizontal={isHorizontal}
    >
      {steps.map((step, index) => (
        <Step key={step.label}>
          <StyledStepLabel
            isHorizontal={isHorizontal}
            key={step.label}
            onClick={() => onChange && onChange(step)}
            StepIconComponent={({ icon, completed }) =>
              !completed ? icon : <Done />
            }
          >
            {step.label}
          </StyledStepLabel>
        </Step>
      ))}
    </StyledStepper>
  </StepperContainer>
);

export default CustomStepper;
