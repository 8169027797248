import { FormHelperText, Grid, styled } from "@mui/material";
import { useFormik } from "formik";
import BlotFormatter from "quill-blot-formatter";
import "quill-divider";
import ImageResize from "quill-image-resize-module-react";
import { useEffect, useState } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";

import { ADD, UPDATE, module } from "../../constants";
import useDispatchActions from "../../hooks/useDispatchActions";
import { CancelButton } from "../../styles/buttonStyle";
import { CustomReactTable } from "./CustomReactTable";
import WithCondition from "./WithCondition";
import { CustomAutoComplete } from "./formFields";
import { CustomDatePicker } from "./formFields/CustomDatePicker";
import { CustomRadioButton } from "./formFields/CustomRadioButton";
import { CustomSelectField } from "./formFields/CustomSelectField";
import { CustomTextField } from "./formFields/CustomTextField";
import { CustomTimePicker } from "./formFields/CustomTimePicker";

Quill.register("modules/imageResize", ImageResize);
Quill.register("modules/blotFormatter", BlotFormatter);

export const CustomTitle = styled("span")(({ theme }) => ({
  textTransform: "capitalize",
  marginRight: "20px",
  font: "normal normal 600 18px/17px Lato",
  color: `${theme?.palette.primary.main}`,
  fontSize: "19px",
  span: {
    fontWeight: "500",
    textTransform: "none",
    fontSize: "18px",
  },
}));

export const MultiRecordField = ({
  isViewMode,
  initialValues,
  fields,
  columnData,
  parentValue = [],
  parentSetFieldValue,
  name,
  validation,
  inputValues,
  customOnSubmit,
  customValidation,
  isAssigned,
  error,
  tableSize,
  uniqueFields,
  children = null,
  getFieldData,
  disableLayout,
  customDelete,
  tableName,
}) => {
  const [tableEditId, setTableEditId] = useState("");
  const { notifyError } = useDispatchActions();
  const checkDuplicate = (value) => {
    const duplicate = uniqueFields?.find((unique) =>
      parentValue?.some((data, index) =>
        unique?.condition
          ? unique?.condition(data, value) && tableEditId !== index
          : data[unique?.key] === value[unique?.key] && tableEditId !== index
      )
    );
    if (duplicate) {
      notifyError(duplicate?.message);
      return true;
    }
    return false;
  };
  const onSubmitForm = (value, { resetForm }) => {
    if (customValidation && customValidation(value, tableEditId)) return;
    if (uniqueFields && checkDuplicate(value)) return;
    if (tableEditId !== "") {
      if (customOnSubmit) {
        customOnSubmit({ tableEditId, value });
      } else {
        parentValue.splice(tableEditId, 1, value);
        parentSetFieldValue(name, [...parentValue]);
      }
      setTableEditId("");
      setValues(initialValues);
      resetForm();
    } else {
      if (customOnSubmit) {
        customOnSubmit({ value });
      } else {
        parentSetFieldValue(name, [...parentValue, value]);
      }
      setValues(initialValues);
      resetForm();
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: onSubmitForm,
    validationSchema: validation,
  });

  const {
    handleChange,
    values,
    handleSubmit,
    setValues,
    setFieldValue,
    setFieldTouched,
    handleBlur,
    errors,
    touched,
  } = formik;

  const handleDeleteList = (id) => {
    if (isAssigned) {
      notifyError("Unable to Delete, Assigned to User");
    } else if (customDelete) {
      customDelete(id);
    } else {
      let copyValue = [...parentValue];
      copyValue.splice(id, 1);

      parentSetFieldValue(name, copyValue);
    }
  };

  const handleEditList = (id) => {
    setValues({ ...parentValue?.[id] });
    setTableEditId(id);
  };

  useEffect(() => {
    if (getFieldData) getFieldData(values);
  }, [values]); //eslint-disable-line

  return (
    <>
      {fields?.map((item, index) => {
        if (item?.isChildren) return children;
        if (item?.empty) return <Grid item xs={item?.xs || 6} key={index} />;
        if (item.type === "select")
          return (
            <Grid item xs={item.xs || 6} key={index}>
              <CustomSelectField
                label={item.label}
                name={item.name}
                inputValues={inputValues?.[item.name] || inputValues || []}
                value={values?.[item.name]}
                onChange={(e, v) => setFieldValue(item.name, v?.id)}
                errors={errors?.[item.name]}
                touched={touched?.[item.name]}
                onBlur={handleBlur}
                accessorReturn={item.accessorReturn}
                accessor={item.accessor}
                isViewMode={isViewMode}
                getOptionLabel={item?.getOptionLabel}
              />
            </Grid>
          );
        if (item.type === "text")
          return (
            <Grid item xs={item.xs || 6} key={index}>
              <CustomTextField
                label={item.label}
                name={item.name}
                fieldType={item?.fieldType}
                fullWidth
                value={values?.[item.name]}
                onChange={handleChange}
                errors={errors?.[item.name]}
                touched={touched?.[item.name]}
                onBlur={handleBlur}
                maxLength={item.maxLength}
                isViewMode={isViewMode}
              />
            </Grid>
          );

        if (item.type === "number")
          return (
            <WithCondition isValid={!item?.hide} key={item?.label+item?.name}>
              <Grid item xs={item.xs || 6} key={item?.label}>
                <CustomTextField
                  label={item.label}
                  name={item.name}
                  RestrictCopyPaste={true}
                  type={item.type}
                  fieldType={item.fieldType}
                  onBlur={handleBlur}
                  value={values?.[item.name]}
                  onChange={(e) => {
                    setFieldValue(item.name, Number(e.target.value));
                  }}
                  errors={errors?.[item.name]}
                  touched={touched?.[item.name]}
                  maxLength={item.maxLength}
                  isViewMode={isViewMode}
                />
              </Grid>
            </WithCondition>
          );

        if (item.type === "autocomplete")
          return (
            <Grid item xs={item.xs || 6} key={index}>
              <CustomAutoComplete
                label={item.label}
                name={item.name}
                onBlur={handleBlur}
                inputValues={inputValues?.[item.name] || []}
                value={values?.[item.name]}
                onChange={(e, newValue) => {
                  setFieldValue([item.name], newValue);
                  if (item?.onChange) item?.onChange(setFieldValue);
                }}
                errors={errors?.[item.name]}
                touched={touched?.[item.name]}
                readOnly={isViewMode}
                getOptionLabel={item?.getOptionLabel || function () {}}
                getOptionDisabled={item?.getOptionDisabled}
              />
            </Grid>
          );

        if (item.type === "date")
          return (
            <Grid item xs={item.xs || 6} key={index}>
              <CustomDatePicker
                label={item.label}
                name={item.name}
                fieldType={item.fieldType}
                fullWidth
                value={values?.[item.name]}
                onChange={setFieldValue}
                setTouched={setFieldTouched}
                errors={errors?.[item.name]}
                touched={touched?.[item.name]}
                onBlur={handleBlur}
                maxDate={item?.max}
                minDate={item?.min}
                isViewMode={isViewMode}
                views={item?.views}
              />
            </Grid>
          );

        if (item.type === "time")
          return (
            <Grid item xs={item.xs || 6} key={index}>
              <CustomTimePicker
                label={item.label}
                name={item.name}
                fieldType={item.fieldType}
                fullWidth
                value={values?.[item.name]}
                onChange={(value) => setFieldValue([item.name], value)}
                setTouched={setFieldTouched}
                errors={errors?.[item.name]}
                touched={touched?.[item.name]}
                onBlur={handleBlur}
                maxTime={item?.maxTime}
                minTime={item?.minTime}
                isViewMode={isViewMode}
                views={item?.views}
              />
            </Grid>
          );

        if (item.type === "radio")
          return (
            <Grid item xs={item.xs || 6} key={index}>
              <CustomRadioButton
                label={item.label}
                name={item.name}
                inputValues={inputValues?.[item.name] || []}
                rowWise={item?.rowWise ? true : false}
                rowBreak={item?.rowBreak ? item?.rowBreak : false}
                value={values?.[item.name]?.toString()}
                accessor={item?.accessor}
                onChange={handleChange}
                errors={errors?.[item.name]}
                touched={touched?.[item.name]}
                onBlur={handleBlur}
                isViewMode={isViewMode}
              />
            </Grid>
          );

        if (item.type === "editor")
          return (
            <>
              <Grid
                item
                xs={item?.xs || 12}
                style={{ height: "350px", position: "relative" }}
              >
                <ReactQuill
                  readOnly={isViewMode}
                  modules={isViewMode ? { toolbar: false } : module}
                  // theme="snow"
                  value={values?.[item.name]?.toString() || ""}
                  onChange={(value) => setFieldValue(item?.name, value)}
                  style={{ height: "250px" }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormHelperText error>
                  {touched?.[item.name] && errors?.[item.name]}
                </FormHelperText>
              </Grid>
            </>
          );

        if (item?.type === "title")
          return (
            <Grid item xs={12}>
              <CustomTitle>{item?.title}</CustomTitle>
            </Grid>
          );

        return "";
      })}
      <WithCondition isValid={!isViewMode}>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex ",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <CancelButton onClick={handleSubmit}>
            {tableEditId !== "" ? UPDATE : ADD}
          </CancelButton>
        </Grid>
        <Grid item xs={12} sx={{ color: "red" }}>
          {error}
        </Grid>
      </WithCondition>

      <WithCondition isValid={tableName}>
        <Grid item xs={12}>
          <CustomTitle>{tableName}</CustomTitle>
        </Grid>
      </WithCondition>

      <Grid item xs={tableSize || 12}>
        <WithCondition isValid={parentValue?.length > 0}>
          <CustomReactTable
            columnData={
              columnData({
                handleDeleteList,
                inputValues,
                tableEditId,
                handleEditList,
                isViewMode,
              }) || []
            }
            rawData={parentValue || []}
            disablePagination
            disableSort
            disableColumnHiding={true}
            disableLayout={disableLayout}
          />
        </WithCondition>
      </Grid>
    </>
  );
};
