import { useTheme } from "@emotion/react";
import { Tooltip } from "@mui/material";

const CustomToolTip = ({
  children,
  style,
  title = "",
  placement = "right",
  leaveDelay = 0,
}) => {
  const theme = useTheme();
  return (
    <Tooltip
      title={title}
      placement={placement}
      leaveDelay={leaveDelay}
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: theme.palette?.background?.paper,
            color: theme.palette?.primary?.light,
            ...style,
          },
        },
      }}
    >
      {children}
    </Tooltip>
  );
};

export default CustomToolTip;
