import {
  Business,
  Campaign,
  Group,
  ListAlt,
  Settings,
  Topic,
  TrendingUp,
  Dashboard,
} from "@mui/icons-material";
import { ROLES } from ".";
import { ROUTE_PATHS } from "../routes/routePaths";

export const getRightSideNavs = (isAdmin) => {
  if (isAdmin) {
    return [
      {
        label: "Profile",
        routePath: ROUTE_PATHS.PROFILE,
      },
      {
        label: "Version Control",
        routePath: ROUTE_PATHS.VERSION_CONTROL_LIST,
      },
      {
        label: "Parameter",
        routePath: ROUTE_PATHS.KNOWN_SOURCE,
      },
      {
        label: "Global Parameter",
        routePath: ROUTE_PATHS.SEND_OPTIONS,
      },
      {
        label: "Logout",
        routePath: ROUTE_PATHS.LOGIN,
      },
    ];
  }
  return [
    {
      label: "Profile",
      routePath: ROUTE_PATHS.PROFILE,
    },
    {
      label: "Parameter",
      routePath: ROUTE_PATHS.KNOWN_SOURCE,
    },
    // {
    //   label: "Global Parameter",
    //   routePath: ROUTE_PATHS.SEND_OPTIONS,
    // },
    {
      label: "Logout",
      routePath: ROUTE_PATHS.LOGIN,
    },
  ];
};

export const sideMenusWithRole = {
  [ROLES.SUPER_ADMIN]: [
    {
      label: "Dashboard",
      key: "dashboard",
      value: "dashboard",
      navigateTo: ROUTE_PATHS.DASHBOARD,
      level: 1,
      Icon: <Dashboard />,
    },
    {
      label: "Customers",
      key: "customers",
      value: "customers",
      navigateTo: ROUTE_PATHS.CUSTOMER_LIST,
      level: 1,
      Icon: <Business />,
    },
    {
      label: "Users",
      key: "users",
      value: "users",
      navigateTo: ROUTE_PATHS.USERS_LIST,
      level: 1,
      Icon: <Group />,
    },
    {
      label: "Master",
      key: "master",
      value: "master",
      level: 1,
      Icon: <Topic />,
      children: [
        {
          name: "Lead Status",
          label: "Lead Status",
          value: "master/leadStatus",
          navigateTo: ROUTE_PATHS.LEAD_STATUS_LIST,
          level: 2,
        },
        {
          name: "Stages",
          label: "Stages",
          value: "master/stages",
          navigateTo: ROUTE_PATHS.STAGES_LIST,
          level: 2,
        },
        {
          name: "Actions",
          label: "Actions",
          value: "master/actions",
          navigateTo: ROUTE_PATHS.ACTIONS_LIST,
          level: 2,
        },
        {
          name: "Action Result",
          label: "Action Result",
          value: "master/actionResult",
          navigateTo: ROUTE_PATHS.ACTION_RESULTS_LIST,
          level: 2,
        },
        {
          name: "Messages",
          label: "Messages",
          key: "messages",
          value: "master/messages",
          navigateTo: ROUTE_PATHS.MESSAGE_MASTER_LIST,
          level: 2,
        },
      ],
    },

    {
      label: "Pipeline",
      key: "pipeline",
      value: "pipeline",
      level: 1,
      Icon: <Settings />,
      children: [
        {
          name: "Master",
          label: "Master",
          key: "master",
          value: "pipeline/master",
          navigateTo: ROUTE_PATHS.PIPELINE_MASTER_LIST,
          level: 2,
        },
        {
          name: "Config",
          label: "Config",
          key: "config",
          value: "pipeline/config",
          navigateTo: ROUTE_PATHS.PIPELINE_CONFIG_LIST,
          level: 2,
        },
        // {
        //   name: "Source Mapping",
        //   label: "Source Mapping",
        //   key: "sourceMapping",
        //   value: "pipeline/mapping",
        //   navigateTo: ROUTE_PATHS.PIPELINE_MAPPING_LIST,
        //   level: 2,
        // },
      ],
    },
    {
      name: "Campaign",
      label: "Campaign",
      value: "campaigns",
      navigateTo: ROUTE_PATHS.CAMPAIGN_LIST,
      level: 1,
      Icon: <Campaign />,
    },
    {
      label: "Lead Master",
      key: "leads",
      value: "leads",
      navigateTo: ROUTE_PATHS.LEADS_LIST,
      level: 1,
      Icon: <ListAlt />,
    },
    {
      label: "Track Leads",
      key: "trackLeads",
      value: "trackLeads",
      navigateTo: ROUTE_PATHS.TRACK_LEADS,
      level: 1,
      Icon: <TrendingUp />,
    },
  ],
  user: [
    {
      label: "Dashboard",
      key: "dashboard",
      value: "dashboard",
      navigateTo: ROUTE_PATHS.DASHBOARD,
      level: 1,
      Icon: <Dashboard />,
    },
    {
      label: "Users",
      key: "users",
      value: "users",
      navigateTo: ROUTE_PATHS.USERS_LIST,
      level: 1,
      Icon: <Group />,
    },
    {
      label: "Master",
      key: "master",
      value: "master",
      level: 1,
      Icon: <Topic />,
      children: [
        {
          name: "Lead Status",
          label: "Lead Status",
          value: "master/leadStatus",
          navigateTo: ROUTE_PATHS.LEAD_STATUS_LIST,
          level: 2,
        },
        {
          name: "Stages",
          label: "Stages",
          value: "master/stages",
          navigateTo: ROUTE_PATHS.STAGES_LIST,
          level: 2,
        },
        {
          name: "Actions",
          label: "Actions",
          value: "master/actions",
          navigateTo: ROUTE_PATHS.ACTIONS_LIST,
          level: 2,
        },
        {
          name: "Action Result",
          label: "Action Result",
          value: "master/actionResult",
          navigateTo: ROUTE_PATHS.ACTION_RESULTS_LIST,
          level: 2,
        },
        {
          name: "Messages",
          label: "Messages",
          value: "master/messages",
          navigateTo: ROUTE_PATHS.MESSAGE_MASTER_LIST,
          level: 2,
        },
      ],
    },
    // {
    //   label: "Campaigns",
    //   key: "campaigns",
    //   value: "campaigns",
    //   navigateTo: ROUTE_PATHS.CAMPAIGN_LIST,
    //   level: 1,
    //   Icon: <Quiz />,
    // },
    {
      label: "Pipeline",
      key: "pipeline",
      value: "pipeline",
      level: 1,
      Icon: <Settings />,
      children: [
        {
          name: "Master",
          label: "Master",
          key: "master",
          value: "pipeline/master",
          navigateTo: ROUTE_PATHS.PIPELINE_MASTER_LIST,
          level: 2,
        },
        {
          name: "Config",
          label: "Config",
          key: "config",
          value: "pipeline/config",
          navigateTo: ROUTE_PATHS.PIPELINE_CONFIG_LIST,
          level: 2,
        },
        // {
        //   name: "Source Mapping",
        //   label: "Source Mapping",
        //   key: "sourceMapping",
        //   value: "pipeline/mapping",
        //   navigateTo: ROUTE_PATHS.PIPELINE_MAPPING_LIST,
        //   level: 2,
        // },
      ],
    },
    {
      label: "Campaign",
      key: "Campaign",
      value: "campaigns",
      navigateTo: ROUTE_PATHS.CAMPAIGN_LIST,
      level: 1,
      Icon: <Campaign />,
    },
    {
      label: "Lead Master",
      key: "leads",
      value: "leads",
      navigateTo: ROUTE_PATHS.LEADS_LIST,
      level: 1,
      Icon: <ListAlt />,
    },
    {
      label: "Track Leads",
      key: "trackLeads",
      value: "trackLeads",
      navigateTo: ROUTE_PATHS.TRACK_LEADS,
      level: 1,
      Icon: <TrendingUp />,
    },
  ],
  default: [],
};

export const getSideMenus = (role, portal = "default") => {
  if (role === ROLES?.SUPER_ADMIN || role === ROLES?.ADMIN)
    return sideMenusWithRole?.[portal];
  return [];
};
