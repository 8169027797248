import { styled, TextField } from "@mui/material";
import React, { forwardRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const StyledInput = styled(TextField)(({ theme }) => ({
  "& :hover": {
    cursor: "pointer",
  },
  "& input": {
    //to remove blinking cursor
    color: theme.palette.text.primary,
    textShadow: "0 0 0 #000",
  },
}));

export const DatePickerRangeField = ({ startDate, endDate, setDateRange }) => {
  const onChange = (dates) => {
    const [start, end] = dates;
    setDateRange({
      startDate: start,
      endDate: end,
    });
  };

  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <StyledInput
      variant={"outlined"}
      type={"text"}
      onClick={onClick}
      ref={ref}
      value={value}
      autoComplete="off"
      placeholder="Select date range"
      size="small"
    />
  ));

  return (
    <DatePicker
      customInput={<CustomInput />}
      //   selected={startDate}
      onChange={onChange}
      startDate={startDate}
      endDate={endDate}
      selectsRange
      isClearable
      clearButtonClassName="dateRangeClearBtn"
    />
  );
};
