import React from "react";
import {
  MultiRecordTitle,
  SideBarBodyContainer,
  SideBarBox,
  SideBarHeaderContainer,
} from "styles";

const SideBarContainer = ({ header = "Side Bar Header", children }) => {
  return (
    <SideBarBox>
      <SideBarHeaderContainer>
        <MultiRecordTitle>{header}</MultiRecordTitle>
      </SideBarHeaderContainer>
      <SideBarBodyContainer>{children}</SideBarBodyContainer>
    </SideBarBox>
  );
};

export default SideBarContainer;
