import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import ScreenHelp from "../ScreenHelp";
import WithCondition from "../WithCondition";

export const CustomSelectField = ({
  label,
  variant,
  inputValues,
  name,
  onChange,
  onBlur,
  value = "",
  className,
  style,
  fieldStyle,
  onOpen,
  isViewMode,
  disabled,
  touched,
  errors,
  customHelpertext,
  accessor,
  getOptionLabel,
  help,
  size,
}) => (
  <>
    <Box style={style}>
      <FormControl
        fullWidth
        error={Boolean(customHelpertext || (touched && errors))}
      >
        <InputLabel id="demo-simple-select-error-label">{label}</InputLabel>
        <Select
          labelid="demo-simple-select-error-label"
          id="demo-simple-select"
          value={value && inputValues?.length ? value : ""}
          label={label}
          onChange={onChange}
          variant={variant || "outlined"}
          fullWidth
          onOpen={onOpen}
          name={name}
          onBlur={onBlur}
          error={Boolean(customHelpertext || (touched && errors))}
          className={className}
          style={fieldStyle}
          inputProps={{
            readOnly: Boolean(isViewMode),
            disabled: Boolean(disabled),
          }}
          size={size || "medium"}
        >
          {inputValues?.map((option, index) => {
            return (
              <MenuItem key={index} value={option?.id || option?.value}>
                {(getOptionLabel && getOptionLabel(option)) ||
                  option?.[accessor] ||
                  option?.name ||
                  option?.label ||
                  "No data Found"}
              </MenuItem>
            );
          })}
        </Select>
        <WithCondition isValid={errors}>
          <FormHelperText error>
            {customHelpertext || (touched && errors)}
          </FormHelperText>
        </WithCondition>
      </FormControl>
    </Box>
    <WithCondition isValid={help}>
      <span>
        <ScreenHelp
          htmlText={help}
          isField
          buttonStyle={{ top: 0, bottom: -16 }}
        />
      </span>
    </WithCondition>
  </>
);
