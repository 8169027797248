import {
  ContentCopy,
  CreateOutlined,
  VisibilityOutlined,
} from "@mui/icons-material";
import MoreHorizTwoToneIcon from "@mui/icons-material/MoreHorizTwoTone";
import {
  IconButton,
  Popover as MuiPopper,
  Tooltip,
  styled,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import useDispatchActions from "hooks/useDispatchActions";
import { useState } from "react";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import WithCondition from "./WithCondition";

const PopoverComponent = styled(MuiPopper)({
  "& .MuiPaper-root": {
    minWidth: "150px",
  },
});

const Titles = styled(Typography)({
  padding: "14px !important",
  cursor: "pointer !important",
  font: "normal normal normal 16px/19px sans-serif !important",
});

export const EditPopover = ({
  inputValues = [],
  disable,
  hasSideBar = false,
  setCustomerId = false,
}) => {
  const [searchParam, setSearchParam] = useSearchParams();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const { notifyError, notifySuccess } = useDispatchActions();
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNavigate = (id, path, view, search, stateProps, customerId) => {
    if (hasSideBar) {
      const newParams = new URLSearchParams(searchParam);
      newParams.set("editId", id);
      setSearchParam(newParams);
      return;
    }
    if (path)
      navigate(
        {
          pathname: path,
          search: `?${createSearchParams({
            editId: id,
            ...search,
            ...(setCustomerId ? { customerId } : {}),
          })}`,
        },
        { state: { viewDetails: Boolean(view), ...stateProps } }
      );
    setAnchorEl(null);
  };

  const handleCopy = () => {
    navigator.clipboard
      .writeText(copyDetail?.copyDetails)
      .then(() => {
        notifySuccess(copyDetail?.successMessage);
      })
      .catch((err) => {
        notifyError(`${copyDetail?.errorMessage}`);
        console.error(err);
      });
  };

  const editDetail = inputValues?.find((item) => item?.label === "Edit");
  const viewDetail = inputValues?.find((item) => item?.label === "View");
  const copyDetail = inputValues?.find((item) => item?.label === "Copy");
  const filtered = inputValues?.filter(
    (item) =>
      item?.label !== "Edit" &&
      item?.label !== "View" &&
      item?.label !== "Copy" &&
      !item?.Icon
  );

  return (
    <div
      style={{ display: "flex", justifyContent: "start", marginLeft: "-10px" }}
    >
      <WithCondition isValid={disable}>
        <span style={{ minHeight: "40px" }}></span>
      </WithCondition>
      <WithCondition isValid={editDetail && !disable && !editDetail?.toHide}>
        <IconButton
          aria-describedby={id}
          onClick={() =>
            editDetail?.customNavigation
              ? editDetail?.customNavigation(id)
              : handleNavigate(
                  editDetail?.id,
                  editDetail?.path,
                  editDetail?.view,
                  editDetail?.search,
                  editDetail?.stateProps,
                  editDetail?.customerId
                )
          }
          disabled={disable}
        >
          <CreateOutlined fontSize="10px" color="primary" />
        </IconButton>
      </WithCondition>
      <WithCondition isValid={viewDetail && !disable}>
        <IconButton
          aria-describedby={id}
          onClick={() =>
            viewDetail?.customNavigation
              ? viewDetail?.customNavigation(id)
              : handleNavigate(
                  viewDetail?.id,
                  viewDetail?.path,
                  viewDetail?.view,
                  viewDetail?.search,
                  viewDetail?.stateProps
                )
          }
          disabled={disable}
        >
          <VisibilityOutlined fontSize="10px" color="primary" />
        </IconButton>
      </WithCondition>
      <WithCondition isValid={copyDetail && !disable}>
        <IconButton
          aria-describedby={id}
          onClick={handleCopy}
          disabled={disable}
        >
          <ContentCopy fontSize="10px" color="primary" />
        </IconButton>
      </WithCondition>
      <WithCondition isValid={filtered?.length}>
        <IconButton
          aria-describedby={id}
          onClick={handleClick}
          disabled={disable}
        >
          <MoreHorizTwoToneIcon />
        </IconButton>
      </WithCondition>

      {inputValues?.map(
        (
          {
            id,
            path,
            toHide,
            customNavigation,
            view,
            search,
            stateProps = {},
            Icon,
            toolTip,
          },
          index
        ) => (
          <WithCondition isValid={!toHide && !!Icon && !disable} key={index}>
            <Tooltip title={toolTip || ""}>
              <IconButton
                aria-describedby={id}
                onClick={() =>
                  customNavigation
                    ? customNavigation(id)
                    : handleNavigate(id, path, view, search, stateProps)
                }
                disabled={!!disable}
              >
                <Icon fontSize="10px" color="primary" />
              </IconButton>
            </Tooltip>
          </WithCondition>
        )
      )}

      <WithCondition isValid={open}>
        <PopoverComponent
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          {inputValues?.map(
            (
              {
                id,
                path,
                label,
                toHide,
                customNavigation,
                customComp,
                view,
                search,
                stateProps = {},
                Icon,
              },
              index
            ) => (
              <WithCondition isValid={!toHide && !Icon} key={index}>
                <WithCondition
                  isValid={!customComp}
                  nullComponent={<Titles>{customComp}</Titles>}
                >
                  <Titles
                    onClick={() =>
                      customNavigation
                        ? customNavigation(id)
                        : handleNavigate(id, path, view, search, stateProps)
                    }
                  >
                    {label}
                  </Titles>
                </WithCondition>
              </WithCondition>
            )
          )}
        </PopoverComponent>
      </WithCondition>
    </div>
  );
};
