import { Button, Typography } from "@mui/material";
import React from "react";

export const RenderFilterOption = ({
  option,
  selected,
  optionKey = "name",
  canSelectMultiple = true,
  name = "",
  onChange = () => {},
  renderOptionLabel,
}) => {
  return (
    <Button
      sx={{
        display: "flex",
        columnGap: "8px",
        padding: "8px",
        cursor: "pointer",
        marginLeft: "auto",
        marginRight: "auto",
        textTransform: "none",
        width: "100%",
        justifyContent: "flex-start",
      }}
      onClick={() => onChange(option)}
    >
      <input
        type={canSelectMultiple ? "checkbox" : "radio"}
        name={name}
        checked={selected}
      />
      <Typography sx={{ fontSize: "12px" }}>
        {renderOptionLabel
          ? renderOptionLabel(option)
          : option?.[optionKey] || option?.name || option?.label}
      </Typography>
    </Button>
  );
};

export default RenderFilterOption;
