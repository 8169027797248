import queryString from "query-string";
import { useCallback, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { sortedValues } from "../utils/common";
import { createQueryParams } from "../utils/queryParams";

export default function useTableCustomHooks(path) {
  const location = useLocation();
  const navigate = useNavigate();

  const pageParams = queryString.parse(location?.search);
  const pageSize = parseInt(pageParams?.pageSize) || 10;
  const currentPage = parseInt(pageParams?.currentPage) || 1;

  const search = location.search;
  const searchParam = useMemo(() => new URLSearchParams(search), [search]);
  const searchdata = searchParam?.get("search");

  const getParams = useMemo(() => {
    const filterdata = searchParam?.get("filter")
      ? JSON.parse(searchParam?.get("filter"))
      : [];
    const sortData = searchParam?.get("sort")
      ? JSON.parse(searchParam?.get("sort"))
      : [];
    return { filterdata, sortData };
  }, [searchParam]);

  const { filterdata, sortData } = getParams;

  const onPageNumberChange = useCallback(
    (page) => {
      const newParams = createQueryParams({
        ...pageParams,
        pageSize,
        currentPage: page,
      });
      return navigate(`${path}?${newParams}`);
    },
    [navigate, pageParams, pageSize, path]
  );

  const onChangePageSize = useCallback(
    (size) => {
      const newParams = createQueryParams({
        ...pageParams,
        pageSize: size,
        currentPage: size === pageSize ? currentPage : 1,
      });
      return navigate(`${path}?${newParams}`);
    },
    [currentPage, navigate, pageParams, pageSize, path]
  );

  const handleTableDatas = useCallback(
    (searchFields, columnDatas, defaultSortedValues, initialPageSize) => {
      const offset = (initialPageSize || pageSize) * (currentPage - 1);
      const listParams = {
        pagination: { limit: initialPageSize || pageSize, offset },
        search: {
          fields: searchFields,
          value: searchdata ? searchdata : "",
        },
        sorting:
          sortData.length === 0
            ? defaultSortedValues || [{ column: "createdAt", order: "desc" }]
            : sortedValues(columnDatas, sortData) || [],
        filters: filterdata?.length !== 0 ? filterdata : [],
      };
      return listParams;
    },
    [currentPage, filterdata, pageSize, searchdata, sortData]
  );

  const tableReRenderActions = () => {
    return { pageSize, sortData, searchdata, currentPage, filterdata };
  };

  return {
    onPageNumberChange,
    onChangePageSize,
    handleTableDatas,
    tableReRenderActions,
  };
}
