import {
  AppBar,
  Avatar,
  Box,
  Grid,
  IconButton,
  Paper,
  styled,
} from "@mui/material";

export const AppContainerLayout = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100vh",
  display: "flex",
  backgroundColor: theme.palette.background.default,
  flexDirection: "column",
  "& .appMainContainer": {
    display: "flex",
    height: "calc(100vh - 50px)",
  },
}));

export const AppMainContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  height: "calc(100vh - 50px)",
}));

export const AppBarLayout = styled(AppBar)(({ theme }) => ({
  width: "100%",
  minHeight: "50px",
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "row",
  alignItems: "center",
  position: "sticky",
  backgroundColor: theme?.palette?.background?.paper,
  color: theme?.palette?.primary.main,
  boxShadow: "none",
}));

export const AppLogo = styled("div")({
  height: "100%",
  width: "auto",
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",
  cursor: "pointer",
  gap: "15px",
  marginLeft: "10px",
});

export const CommonAvatar = styled(Avatar)({
  width: 40,
  height: 40,
});

export const AppProfile = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "auto",
  height: "100%",
  cursor: "pointer",
  "& .appProfileDetails": {
    marginRight: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  marginRight: "15px",
});

export const AppMainLayout = styled("div")(({ theme }) => ({
  width: "100%",
  overflow: "auto",
  height: "calc(100vh - 50px)",
  "&::-webkit-scrollBar": {
    width: 0,
    height: 0,
  },
  position: "relative",
  backgroundColor: theme.palette?.background?.default,
}));

export const FormContainer = styled(Box)({
  width: "100%",
  maxHeight: "100%",
  overflow: "scroll",
  "&::-webkit-scrollbar": {
    width: 0,
    height: 0,
  },
});

export const FormLayout = styled(Box)(({ theme }) => ({
  maxHeight: `calc(100% - 92px)`,
  minHeight: `calc(100% - 92px)`,
  width: "100%",
  display: "flex",
  justifyContent: "center",
  overflow: "auto",
  "&::-webkit-scrollbar": {
    width: "5px",
    height: "5px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#6E6E6E60",
    borderRadius: 5,
  },
  "&::-webkit-scrollbar-thumb:hover": {
    background: "#555",
  },
}));

export const StyledFormContainer = styled(Paper)(
  ({ theme, width, style = {} }) => ({
    width: width ?? "60%",
    margin: "0px auto 30px",
    padding: "40px",
    height: "fit-content",
    borderRadius: 10,
    border: "1px solid",
    borderColor: theme.palette.background.default,
    boxSizing: "border-box",
    backgroundColor: theme.palette.common.white,
    boxShadow: "none",
    minWidth: "500px",
    ...style,
  })
);

export const FileDownloadLayout = styled(Grid)({
  display: "flex",
  alignItems: "center",
  paddingTop: 16,
});

export const SearchContainer = styled(Box)({
  width: "89.8%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  marginLeft: "5%",
  marginBottom: 16,
});

export const CustomHeader = styled("div")(({ theme }) => {
  return {
    fontSize: 22,
    marginLeft: 15,
    fontFamily: "lato",
    userSelect: "none",
    color: theme?.palette?.primary?.main,
    fontWeight: "700",
  };
});

export const MenuHideButtonWrapper = styled(Box)({
  position: "absolute",
  top: "44%",
  left: "-12px",
  height: "60px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: "#B9D7EA",
  borderRadius: "0 12px 12px 0",
  cursor: "pointer",
  ":hover": {
    left: 0,
  },
});

export const MenuHideButton = styled(IconButton)({
  padding: "0 6px 0 0",
  width: "20px",
});
