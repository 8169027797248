import { useDispatch, useSelector } from "react-redux";

import { useEffect, useMemo } from "react";
import { postApiServices } from "../../api/api";
import { apiPaths } from "../../api/apiPaths";
import { useCustomQuery } from "../../hooks/useCustomQuery";
import useTableCustomHooks from "../../hooks/useTableCustomHooks";
import { setIsLoading } from "../../redux/slice";
import { ROUTE_PATHS } from "../../routes/routePaths";
import { getTableSchemas } from "../../utils/tableSchemas";
import { CustomReactTable } from "./CustomReactTable";
import { ListTopBar } from "./ListTopBar";
import WithCondition from "./WithCondition";

const CommonList = ({
  listPath,
  formPath,
  apiPath,
  columns,
  label,
  defaultSortedValues,
  disableTopBar,
  disableLayout,
  customApiPath,
  enableFilters,
  addFilters = [],
  additionalComponent,
  additionalTopBarPosition,
  refetchList,
  stopRefetch,
  addQueryData = {},
  disableSearch,
  disableFilter,
  disableFetch,
  navigateOnBack,
  newButtonLabel,
  SpacerPanel = <></>,
  showCount,
}) => {
  const dispatch = useDispatch();
  const isAdmin = useSelector((state) => state?.isAdmin);
  const userInfo = useSelector((state) => state?.userInfo);
  const { searchFields, filterFields, filterInitialValues } = useMemo(
    () => getTableSchemas(columns),
    [columns]
  );
  const {
    onPageNumberChange,
    onChangePageSize,
    handleTableDatas,
    tableReRenderActions,
  } = useTableCustomHooks(ROUTE_PATHS?.[listPath]);

  const listParams = useMemo(
    () => handleTableDatas(searchFields, columns, defaultSortedValues),
    [columns, defaultSortedValues, handleTableDatas, searchFields]
  );

  const filterByOrganization = () => {
    let filter = [];
    if (!isAdmin) {
      if (userInfo?.customerId) {
        // filter = [createFilter("organizationId", userInfo?.organizationId)];
        filter = [];
      }
    }
    return filter;
  };
  const branchFilter = enableFilters ? filterByOrganization() : [];
  const filters = [...listParams.filters, ...branchFilter, ...addFilters];

  const { pageSize, currentPage } = tableReRenderActions();

  const { data, isLoading, refetch } = useCustomQuery({
    queryKey: [
      "commonList" + apiPaths?.[apiPath],
      { ...listParams, filters, addQueryData },
    ],
    queryFn: () => {
      const path = customApiPath || apiPaths?.[apiPath];
      return postApiServices(path, {
        ...listParams,
        filters,
        ...addQueryData,
      });
    },
    enabled: (!!customApiPath || !!apiPaths?.[apiPath]) && !disableFetch,
    onSuccess: () => {
      if (refetchList) {
        dispatch(setIsLoading(false));
        stopRefetch && stopRefetch(false);
      }
    },
  });

  useEffect(() => {
    if (refetchList) refetch();
  }, [refetchList]); //eslint-disable-line

  return (
    <>
      <WithCondition isValid={!disableTopBar}>
        <ListTopBar
          label={label || ""}
          newFormPath={ROUTE_PATHS?.[formPath]}
          listPath={ROUTE_PATHS?.[listPath]}
          filterFields={filterFields}
          filterFieldInitial={filterInitialValues}
          showFilterApplied
          additionalComponent={additionalComponent}
          addPosition={additionalTopBarPosition}
          currentFilters={listParams.filters}
          disableSearchField={disableSearch}
          disableFilter={disableFilter}
          navigateOnBack={navigateOnBack}
          newButtonLabel={newButtonLabel}
          showCount={showCount && data?.data?.count}
          count={data?.data?.count}
        />
      </WithCondition>

      <WithCondition isValid={SpacerPanel}>{SpacerPanel}</WithCondition>

      <CustomReactTable
        columnData={columns || []}
        rawData={data?.data?.rows || []}
        isLoading={isLoading}
        onPageNumberChange={onPageNumberChange}
        onChangePageSize={onChangePageSize}
        pageSize={pageSize}
        currentPage={currentPage}
        count={data?.data?.count}
        disableLayout={disableLayout}
      />
    </>
  );
};

export default CommonList;
