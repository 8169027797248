import CloseIcon from "@mui/icons-material/Close";
import { Grid, Modal, Typography, styled } from "@mui/material";

import { useTheme } from "@emotion/react";
import {
  CancelButton,
  FilterButtonModal,
  FilterContainerStyle,
  FilterTitle,
  SubmitButton,
} from "../../styles";
import WithCondition from "./WithCondition";

const ModalName = styled(Typography)({
  fontSize: 16,
  width: 100,
});

const GridContainer = styled(Grid)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  margin: 0,
  paddingInline: 24,
  paddingTop: 16,
});

const WithModal = ({
  open,
  onClose,
  title = "",
  onConfirm,
  children,
  submitLabel = "OKAY",
  clearLabel = "CANCEL",
  disableClear = false,
  alignButtons = "center",
  disableSubmit,
  containerStyle = {},
  additionalComponent,
  warning,
}) => {
  const theme = useTheme();
  return (
    <Modal
      open={!!open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <FilterContainerStyle>
        <FilterTitle>
          <ModalName
            id="modal-modal-title"
            sx={{ color: theme.palette.background.paper, width: "auto" }}
          >
            {title}
          </ModalName>
          {additionalComponent ?? null}
          <CloseIcon
            onClick={onClose}
            fontSize={"small"}
            style={{ cursor: "pointer" }}
          />
        </FilterTitle>
        <GridContainer container sx={containerStyle}>
          {children}
        </GridContainer>
        <WithCondition isValid={!disableClear || !disableSubmit}>
          <FilterButtonModal sx={{ justifyContent: alignButtons }}>
            <WithCondition isValid={!disableClear}>
              <CancelButton
                sx={{ px: 3 }}
                onClick={onClose}
                style={{ cursor: "pointer" }}
              >
                {clearLabel}
              </CancelButton>
            </WithCondition>

            <WithCondition isValid={!disableSubmit}>
              <SubmitButton
                variant="contained"
                type="submit"
                sx={{
                  px: 3,
                  backgroundColor: warning ? "error.main" : "primary.main",
                }}
                onClick={onConfirm}
                warning
              >
                {submitLabel}
              </SubmitButton>
            </WithCondition>
          </FilterButtonModal>
        </WithCondition>
      </FilterContainerStyle>
    </Modal>
  );
};

export default WithModal;
