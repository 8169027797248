import { HelpOutline } from "@mui/icons-material";
import { Box, IconButton, useTheme } from "@mui/material";
import { useMemo, useState } from "react";
import WithModal from "./WithModal";
const defaultButtonStyle = {
  paddingBlock: 0,
  // marginTop: "16px",
  paddingInline: 0,
  position: "absolute",
  zIndex: 999,
};

const ScreenHelp = ({
  detail = "",
  htmlText = "",
  style = {},
  buttonStyle = {},
  iconStyle = {},
  isField,
}) => {
  const theme = useTheme();
  const defaultIconStyle = useMemo(
    () => ({
      fontSize: "20px",
      fill: theme.palette.primary.main,
    }),
    [] //eslint-disable-line
  );
  const [open, setOpen] = useState(false);
  const buttonStyles = isField
    ? { ...defaultButtonStyle, ...buttonStyle }
    : buttonStyle;
  const iconStyles = isField ? { ...defaultIconStyle, iconStyle } : iconStyle;
  return (
    <>
      <Box
        sx={{
          width: "100%",
          textAlign: "end",
          marginLeft: "auto",
          height: 0,
          mt: -2,
          mr: -4.5,
          float: "right",
          ...(isField && { marginTop: "-40px", marginRight: "-5px" }),
          ...style,
        }}
      >
        <IconButton onClick={() => setOpen(true)} sx={buttonStyles}>
          <HelpOutline sx={iconStyles} />
        </IconButton>
      </Box>
      <WithModal
        open={open}
        title="Help"
        onClose={() => {
          setOpen(false);
        }}
        onConfirm={() => {
          setOpen(false);
        }}
        alignButtons="flex-end"
        disableClear
      >
        {htmlText ? (
          <div dangerouslySetInnerHTML={{ __html: htmlText }}></div>
        ) : (
          detail
        )}
      </WithModal>
    </>
  );
};

export default ScreenHelp;
