import { TextField } from "@mui/material";
import ScreenHelp from "../ScreenHelp";
import WithCondition from "../WithCondition";

const CustomTextarea = ({
  type,
  name,
  onChange,
  errors,
  touched,
  value,
  variant,
  onBlur,
  label,
  disabled,
  style,
  isViewMode,
  maxLength,
  fullWidth,
  autoComplete,
  onkeydown,
  error,
  helpertext,
  placeholder,
  endAdornment,
  minRows,
  maxRows,
  className,
  help,
}) => (
  <>
    <TextField
      multiline
      maxRows={maxRows}
      minRows={minRows}
      id='standard-basic'
      label={label}
      placeholder={placeholder}
      variant={variant || "outlined"}
      type={type || "text"}
      name={name}
      className={className}
      fullWidth={fullWidth || true}
      autoComplete={autoComplete}
      onChange={onChange}
      onBlur={onBlur}
      value={value || ""}
      sx={style}
      error={Boolean(touched && errors)}
      helperText={
        errors ||
        helpertext ||
        (touched?.[name] && errors?.[name] ? errors?.[name] : "")
      }
      InputProps={{
        endAdornment: endAdornment,
        onKeyDown: (e) => onkeydown && onkeydown(e),
        readOnly: isViewMode,
        disabled: disabled,
      }}
      onInput={(e) => {
        e.target.value &&
          maxLength &&
          (e.target.value = Math.max(0, parseInt(e.target.value))
            .toString()
            .slice(0, maxLength));
      }}
    />
    <WithCondition isValid={help}>
      <span>
        <ScreenHelp
          htmlText={help}
          isField
          buttonStyle={{ top: 0, bottom: -16 }}
        />
      </span>
    </WithCondition>
  </>
);

export default CustomTextarea;
