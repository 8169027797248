import styled from "@emotion/styled";
import { Grid, Typography } from "@mui/material";

const Title = styled(Typography)(({ theme }) => ({
  textAlign: "left",
  font: "normal normal bold 16px/19px Lato",
  letterSpacing: "0px",
  color: theme.palette.text.primary,
  opacity: "1",
}));

const SecTitle = styled(Typography)(({ theme }) => ({
  textAlign: "left",
  font: "normal normal bold 16px/19px Lato",
  letterSpacing: "0px",
  color: theme.palette.text.secondary,
  opacity: "1",
  marginBottom: "5px",
}));

const ValueText = styled(Typography)(({ theme }) => ({
  textAlign: "left",
  font: "normal normal normal 16px/19px Lato",
  letterSpacing: "0px",
  color: theme.palette.text.secondary,
  opacity: "1",
}));

const formatDate = (date) => {
  const dateValue = new Date(date);
  if (!date || dateValue.toString() === "Invalid Date") return "";
  const dateString = dateValue.toLocaleDateString();
  const timeString = dateValue.toLocaleTimeString("en-IN", {
    hour: "2-digit",
    minute: "2-digit",
    hourCycle: "h12",
  });
  return dateString + " - " + timeString;
};

const AuditLog = ({ data, sx, hide }) => {
  if (hide) return null;

  return (
    <Grid
      container
      rowSpacing={2}
      columnSpacing={3}
      sx={{ padding: "10px 5px 0px 20px", marginTop: 0, ...sx }}
    >
      <Grid item xs={12}>
        <Title>AUDIT LOG</Title>
      </Grid>
      <Grid item xs={6}>
        <SecTitle>Created by</SecTitle>
        <ValueText>
          {data?.createdBy?.name
            ? `${data?.createdBy?.name} - ${formatDate(data?.createdAt)}`
            : `${formatDate(data?.createdAt)}`}
        </ValueText>
      </Grid>
      <Grid item xs={6}>
        <SecTitle>Updated by</SecTitle>
        <ValueText>
          {data?.updatedBy?.name
            ? `${data?.updatedBy?.name} - ${formatDate(data?.updatedAt)}`
            : `${formatDate(data?.updatedAt)}`}
        </ValueText>
      </Grid>
    </Grid>
  );
};

export default AuditLog;
