import { ExpandMore } from "@mui/icons-material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import {
  RenderFilterOption,
  ScreenHelp,
  WithCondition,
} from "components/shared";
import { useCallback } from "react";

export const FilterSelectField = ({
  className,
  label,
  onChange,
  onBlur,
  value,
  inputValues = [],
  readOnly,
  name,
  errors,
  touched,
  required,
  accessor,
  fullWidth,
  labelAccessor,
  isReturnObject,
  isViewMode,
  getOptionLabel,
  disabled,
  customOnchange,
  hideClose,
  size,
  help,
  style = {},
  renderOption,
  canSelectMultiple = true,
}) => {
  const handleChange = useCallback(
    (option) => {
      let newValue = option?.id;

      if (canSelectMultiple && !value?.length) {
        newValue = [option];
      }

      if (canSelectMultiple && value?.length) {
        const isSelected = value?.some(
          (val) => val?.[accessor || "id"] === option?.[accessor || "id"]
        );

        newValue = value?.reduce((acc, val) => {
          if (val?.[accessor || "id"] === option?.[accessor || "id"]) {
            return acc;
          }

          return [...acc, val];
        }, []);

        if (!isSelected) {
          newValue.push(option);
        }
      }

      onChange(name, newValue);
    },
    [canSelectMultiple, name, onChange, value]
  );

  return (
    <>
      <Autocomplete
        className={className}
        multiple={canSelectMultiple}
        label={label}
        name={name}
        size={size || "medium"}
        sx={{
          fontSize: "14px",
        }}
        readOnly={readOnly || isViewMode}
        fullWidth={fullWidth || true}
        options={inputValues || []}
        value={
          canSelectMultiple
            ? value
            : inputValues?.find((item) => item?.[accessor || "id"] === value) ||
              null
        }
        disablePortal
        getOptionLabel={(option) =>
          (getOptionLabel && getOptionLabel(option)) ||
          option?.[labelAccessor] ||
          option?.name ||
          option?.label ||
          "No data Found"
        }
        renderOption={
          renderOption
            ? renderOption
            : (props, option, { selected }) => (
                <RenderFilterOption
                  canSelectMultiple={canSelectMultiple}
                  name={name}
                  onChange={handleChange}
                  fieldProps={props}
                  option={option}
                  selected={selected}
                  renderOptionLabel={getOptionLabel}
                />
              )
        }
        onChange={
          customOnchange
            ? customOnchange
            : (e, v) =>
                onChange(
                  name,
                  isReturnObject ? v || { id: "" } : v?.[accessor || "id"] || ""
                )
        }
        renderTags={() => null}
        isOptionEqualToValue={(option, value) =>
          option?.[accessor || "id"] === value?.[accessor || "id"]
        }
        popupIcon={<ExpandMore />}
        onBlur={onBlur}
        disabled={Boolean(disabled)}
        disableClearable={canSelectMultiple}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              {...(inputValues?.length < 5 && {
                onKeyPress: (e) => e.preventDefault(),
              })}
              variant="outlined"
              label={label}
              name={name}
              error={errors && touched}
              helperText={touched && errors}
              fullWidth
              required={required}
              readOnly={readOnly || isViewMode}
              style={style}
              sx={{
                label: { fontSize: "14px", fontWeight: "700", color: "black" },
                input: { fontSize: "14px" },
                // "& .css-1glvl0p-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-clearIndicator":
                //   { display: "none" },
              }}
            />
          );
        }}
      />
      <WithCondition isValid={help}>
        <span>
          <ScreenHelp htmlText={help} isField />
        </span>
      </WithCondition>
    </>
  );
};

export default FilterSelectField;
