import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";

export function useCustomQuery({
  dependency = "",
  queryKey = "fetch",
  queryFn,
  onSuccess = () => {},
  enabled = true,
  invokeSuccess,
  select = (data) => data,
}) {
  const [successCount, setSuccessCount] = useState(-1);
  const query = useQuery({
    queryKey: [queryKey, dependency],
    queryFn: queryFn,
    enabled: enabled,
    select,
  });

  useEffect(() => {
    if (query?.data || invokeSuccess) {
      setSuccessCount((prev) => prev + 1);
      onSuccess(query?.data);
    } // eslint-disable-next-line
  }, [query.data]);

  query.successCount = successCount;

  return query;
}
