import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { ScreenHelp, WithCondition } from "components/shared";
import dayjs from "dayjs";
import "dayjs/locale/en-gb";

export const CustomDatePicker = ({
  label,
  onChange,
  value,
  name,
  isViewMode,
  maxDate,
  minDate,
  className,
  style,
  disabled,
  touched,
  errors,
  setTouched,
  views,
  customOnChange,
  disableMonth,
  help,
  size = "medium",
}) => {
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='en-gb'>
        <DatePicker
          name={name}
          label={label}
          value={value ? dayjs(value) : null}
          minDate={minDate ? dayjs(minDate) : ""}
          maxDate={maxDate ? dayjs(maxDate) : ""}
          views={views || ["year", "month", "day"]}
          style={style}
          autoComplete='off'
          readOnly={Boolean(isViewMode)}
          disabled={disabled}
          closeOnSelect
          className={className}
          shouldDisableMonth={({ $M }) => {
            return disableMonth && disableMonth >= 0 && $M === disableMonth;
          }}
          onChange={
            customOnChange
              ? customOnChange
              : (value) => {
                  onChange(name, value?.$d ? new Date(value?.$d) : null);
                }
          }
          sx={{ width: "100%" }}
          slotProps={{
            textField: {
              onBlur: (e) => {
                !touched && setTouched(name, e.type === "blur");
              },
              error: !!touched && !!errors,
              helperText: !!touched && errors,
              size,
            },
          }}
        />
      </LocalizationProvider>
      <WithCondition isValid={help}>
        <span>
          <ScreenHelp
            htmlText={help}
            isField
            buttonStyle={{ top: 0, bottom: -16 }}
          />
        </span>
      </WithCondition>
    </>
  );
};
