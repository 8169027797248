export const apiPaths = {
  //LOGIN
  LOGIN: "/logIn",
  SET_PASSWORD: "/setPassword",
  CHANGE_PASSWORD: "/changePassword",
  REFRESH_TOKEN: "/refreshToken",
  FORGET_PASSWORD: "/forgetPassword",
  VERIFY_OTP: "/verifyOtp",
  USER_BY_TOKEN: "/userByToken",
  EDIT_PROFILE: "/editProfile",

  //USER
  USERS: "/customerUsers",
  USER: "/customerUser",

  VERSION_CONTROLS: "/appVersions",
  VERSION_CONTROL: "/appVersion",

  LEAD_REPORT: "/leadReport",
  CALL_REPORT: "/callReport",
  LEAD_COUNT_REPORT: "/leadCountReport",

  CUSTOMERS: "/customers",
  CUSTOMER: "/customer",

  LEAD_STATUSES: "/leadStatuses",
  LEAD_STATUS: "/leadStatus",
  LEAD_STATUS_BY_CUSTOMER: "/leadStatusSeed",
  LEAD_STATUS_BY_PIPELINE: "/statusesByPipeline",

  STAGES: "/leadStages",
  STAGE: "leadStage",
  LEAD_STAGES_BY_CUSTOMER: "/leadStageSeed",
  LEAD_STAGES_BY_PIPELINE: "/stagesByPipeline",
  POSSIBLE_STATUSES_OF_STAGE: "/possibleStatusesOfStage",
  POSSIBLE_STAGES_OF_STAGE: "/possibleStagesOfStage",

  ACTIONS: "/leadActions",
  ACTION: "/leadAction",
  LEAD_ACTIONS_BY_CUSTOMER: "/leadActionSeed",
  ACTIONS_BY_PIPELINE_STAGE: "/actionsByPipelineStage",

  RESULTS: "/results",
  RESULT: "/result",
  LEAD_RESULTS_BY_CUSTOMER: "/resultSeed",
  RESULTS_BY_PIPELINE_ACTION: "/resultsByPipelineAction",

  //PARAMETER
  KNOWN_SOURCE: "/KnownSource",
  LEAD_SOURCE: "/Source",
  SEND_OPTIONS: "/SendOptions",
  SOURCES_BY_CUSTOMER: "/sourcesByCustomer",

  SEEDS: ({ type = "" }) => `seeds${type}`,
  MESSAGES: "/messages",
  MESSAGE: "/message",
  MESSAGE_SEED: "/messageSeed",
  MESSAGE_SEED_BY_TYPE: "/getMessageSeedByType",

  CAMPAIGNS: "/campaigns",
  CAMPAIGN: "/campaign",

  PIPELINE_MASTERS: "/pipeLines",
  PIPELINE: "/pipeLine",
  PIPELINES_BY_CUSTOMER: "/pipeLineSeed",

  PIPELINE_CONFIGS: "/pipelineStatuses",
  PIPELINE_CONFIG: "/pipelineStatus",

  PIPELINE_CONFIG_STAGE: "/pipelineConfigStage",
  PIPELINE_CONFIG_ACTION: "/pipelineConfigAction",
  PIPELINE_CONFIG_RESULT: "/pipelineConfigResult",

  PIPELINE_MAPPINGS: "/pipelineMappings",
  PIPELINE_MAPPING: "/pipelineMapping",

  LEADS: "/leads",
  LEAD: "/lead",
  IMPORT_LEADS: "/importLeads",

  LEAD_REQUEST: "/leadRequest",

  TRACK_LEADS: "/trackLeads",
  TRACK_LEAD: "/leadProgress",
  ASSIGN_LEAD: "/assignLead",

  PIPELINE_RESULT: "/pipelineResult",

  DOCEDIT: "/editDoc",
};

export const SEED_APIS = {
  STATE: {
    name: "state",
    path: "State",
  },
  INDUSTRY_TYPE: {
    name: "industryType",
    path: "IndustryType",
  },
  STATUS: {
    name: "status",
    path: "Status",
  },
  FILE_TYPE: {
    name: "fileType",
    path: "FileType",
  },
  ROLE: {
    name: "role",
    path: "Role",
  },
  GENDER: {
    name: "gender",
    path: "Gender",
  },
  QUALIFICATION_TYPE: {
    name: "qualificationTypes",
    path: "QualificationType",
  },
  QUALIFICATION: {
    name: "qualifications",
    path: "Qualification",
  },
  EMPLOYEE_TYPE: {
    name: "employeeType",
    path: "EmployeeType",
  },
  MENUS: {
    name: "menus",
    path: "Menus",
  },
  MAIL_FREQUENCY: {
    name: "mailFrequency",
    path: "MailFrequency",
  },

  MAIL_TYPE: {
    name: "mailTypes",
    path: "MailType",
  },
  USERS: {
    name: "users",
    path: "Users",
  },
  PIPELINE: {
    name: "pipelines",
    path: "Pipeline",
  },
  CUSTOMER: {
    name: "customers",
    path: "Customer",
  },
  DAY_FILTERS: {
    name: "dayFilters",
    path: "DayFilters",
  },
  SEND_OPTIONS: {
    name: "sendOptions",
    path: "SendOptions",
  },
  IMPORT_FIELDS: {
    name: "importFields",
    path: "ImportFields",
  },
};
