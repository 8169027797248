import CancelIcon from "@mui/icons-material/Cancel";
import SearchIcon from "@mui/icons-material/Search";
import { IconButton, InputAdornment } from "@mui/material";
import InputBase from "@mui/material/InputBase";
import { styled } from "@mui/material/styles";
import { useDebounce } from "hooks/useDebounce";
import queryString from "query-string";
import { useEffect, useMemo, useState } from "react";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";

const Search = styled("div")(({ theme }) => {
  return {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "inherit",
    border: `1px solid ${theme.palette.primary.main}`,
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "250px",
  };
});

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 1),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.text.primary,
}));

const StyledCancelIcon = styled(CancelIcon)({
  height: "20px",
  width: "20px",
});

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: theme.palette.text.primary,
  boxSizing: "border-box",
  paddingRight: "12px",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1 / 1.5, 1 / 1.5, 1 / 1.5, 0),
    paddingLeft: `calc(1em + ${theme.spacing(3)})`,
  },
}));

export const CustomSearchField = ({ placeholder, style }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathName = location.pathname;
  const pageParams = queryString.parse(location?.search);
  const search = location.search;
  const searchParam = useMemo(() => new URLSearchParams(search), [search]);
  const searchdata = searchParam?.get("search");

  const [value, setValue] = useState("");
  const debouncedSearch = useDebounce(value, 750);

  const clearSearch = () => {
    const data = {
      ...pageParams,
    };
    delete data?.search;
    setValue("");
    navigate({
      pathName: `${pathName}`,
      search: `?${createSearchParams({
        ...data,
      })}`,
    });
  };

  const onSearchChange = (e) => {
    if (e.charCode === 13) {
      navigate({
        pathName: `${pathName}`,
        search: `?${createSearchParams({
          ...pageParams,
          search: e.target.value.trim(),
          currentPage: 1,
        })}`,
      });
    }
  };

  useEffect(() => setValue(""), [pathName]);

  useEffect(() => {
    if (searchdata) {
      setValue(searchdata);
    }
  }, [searchdata]);

  useEffect(() => {
    if (value?.trim()) {
      navigate({
        pathName: `${pathName}`,
        search: `?${createSearchParams({
          ...pageParams,
          search: value.replace(/\s+/g, " "),
          currentPage: 1,
        })}`,
      });
    } else {
      const data = {
        ...pageParams,
      };
      delete data?.search;
      navigate({
        pathName: `${pathName}`,
        search: `?${createSearchParams({
          ...data,
        })}`,
      });
    }
  }, [debouncedSearch]); //eslint-disable-line

  return (
    <Search style={style}>
      <SearchIconWrapper>
        <SearchIcon />
      </SearchIconWrapper>
      <StyledInputBase
        placeholder={placeholder || "SEARCH"}
        inputProps={{ "aria-label": "search" }}
        name="searchValue"
        value={value}
        sx={{ fontSize: "0.875rem" }}
        onInput={(e) => {
          const currentValue = e.target.value;
          const cursorPosition = e.target.selectionStart;

          if (currentValue) {
            const newValue = currentValue.replace(/\s+/g, " ");
            e.target.value = newValue;

            const adjustment = currentValue.length - newValue.length;
            e.target.setSelectionRange(
              cursorPosition - adjustment,
              cursorPosition - adjustment
            );
          } else {
            e.target.value = "";
          }
        }}
        onChange={(e) => {
          if (e.target.value.trim() === "") {
            navigate({
              pathName: `${pathName}`,
              search: `?${createSearchParams({
                ...pageParams,
                search: e.target.value.trim(),
              })}`,
            });
          }
          setValue(e.target.value.replace(/\s+/g, " "));
        }}
        onKeyPress={(e) => onSearchChange(e)}
        autoComplete="off"
        endAdornment={
          <InputAdornment position="end">
            {value && (
              <IconButton
                aria-label="toggle password visibility"
                edge="end"
                onClick={clearSearch}
              >
                <StyledCancelIcon />
              </IconButton>
            )}
          </InputAdornment>
        }
      />
    </Search>
  );
};
