import {
  Box,
  IconButton,
  Drawer as MuiDrawer,
  Tab,
  Tabs,
  styled,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useTheme } from "@emotion/react";
import { Apps, Menu, MenuOpen } from "@mui/icons-material";
import { ROUTE_PATHS } from "../../routes/routePaths";
import { CustomAccordion } from "./CustomAccordion";
import CustomToolTip from "./CustomToolTip";
import WithCondition from "./WithCondition";

export const CustomTabs = styled(Tabs)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  // border: "1px solid",
  // borderColor: theme?.palette?.primary?.main,
  borderTop: "none",
  // paddingTop: 10,
  paddingBottom: "auto",
  // maxWidth: 350,
  "& .MuiTabs-flexContainer": {
    overflowY: "auto",
    maxHeight: "100%",
    "&::-webkit-scrollbar": {
      width: 0,
      height: 0,
    },
  },
}));

export const CustomTab = styled(Tab)(
  ({ theme, secondary, active, isactive }) => ({
    minHeight: "auto",
    minWidth: "260px",
    fontSize: 15,
    alignItems: "center",
    justifyContent: "flex-start",
    textAlign: "left",
    borderRadius: 3,
    textTransform: "none",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    color: active
      ? theme.palette?.primary?.main
      : theme.palette.background.default,
    backgroundColor: active
      ? theme.palette?.common.white
      : isactive
      ? theme.palette.background?.active
      : "inherit",
    wordWrap: "break-word",
    paddingLeft: secondary ? "40px" : "auto",
    opacity: 1,
    "&.Mui-selected": {
      color: theme?.palette?.primary?.dark,
      backgroundColor: theme?.palette?.background?.paper,
    },
    ".MuiSvgIcon-root": {
      marginRight: "15.5px",
      color: active ? theme.palette?.primary?.main : "inherit",
    },
  })
);

const openedMixin = (theme) => ({
  // width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  // width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  backgroundColor: theme.palette?.primary?.main,
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
  ".MuiDrawer-paper": {
    position: "relative",
    backgroundColor: theme.palette?.primary?.main,
  },
}));

export const SideBarNavigators = ({ menuList = [], portalsCount }) => {
  const [activeMenu, setActiveMenu] = useState(menuList[0]);
  const [value, setValue] = useState(menuList[0]?.value);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const currentPage = pathname.split("/")[1];
  const theme = useTheme();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleMenuClick = (menu) => () => {
    menu?.navigateTo && !menu?.children?.length && navigate(menu?.navigateTo);
    if (menu?.children?.length) {
      setDrawerOpen(true);
      setActiveMenu(menu);
    }
  };

  const handleTab = (event, value) => {
    if (!pathname.includes(value)) return;
    setValue(value);
  };

  useEffect(() => {
    if (!menuList?.length) return;
    const currentMenu = menuList.find((menu) => menu?.value === currentPage);
    setValue(currentMenu?.value || false);
  }, [pathname, activeMenu, menuList]); // eslint-disable-line

  return (
    <Drawer
      variant="permanent"
      open={drawerOpen}
      // onMouseEnter={() => {
      //   setDrawerOpen(true);
      // }}
      // onMouseLeave={() => {
      //   setDrawerOpen(false);
      // }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: drawerOpen ? "flex-end" : "center",
        }}
      >
        <IconButton
          sx={{
            color: "background.default",
            transition: theme.transitions.create("width", {
              easing: theme.transitions.easing.sharp,
              duration: 500,
            }),
          }}
          onClick={() => setDrawerOpen((prev) => !prev)}
        >
          {drawerOpen ? <MenuOpen /> : <Menu />}
        </IconButton>
      </Box>
      <CustomTabs
        orientation="vertical"
        value={value || false}
        onChange={handleTab}
      >
        <WithCondition isValid={portalsCount > 1}>
          <CustomTab
            label={"Services"}
            id={"service"}
            value={"service"}
            key={"Service"}
            onClick={handleMenuClick({ navigateTo: ROUTE_PATHS.PORTALS })}
            icon={
              <CustomToolTip title={drawerOpen ? "" : "services"}>
                <Apps />
              </CustomToolTip>
            }
            iconPosition="start"
          />
        </WithCondition>
        {menuList.map((menu, menuIdx) =>
          menu?.children?.length ? (
            <CustomAccordion
              menuItem={menu}
              onClick={handleMenuClick}
              drawerOpen={drawerOpen}
              key={menu?.label + menuIdx}
            />
          ) : (
            <CustomTab
              label={menu?.label}
              id={menu?.value}
              value={menu?.value}
              key={menu?.label}
              onClick={handleMenuClick(menu)}
              icon={
                <CustomToolTip
                  title={drawerOpen ? "" : menu?.name ?? menu?.label}
                >
                  {menu?.Icon ?? null}
                </CustomToolTip>
              }
              iconPosition="start"
            />
          )
        )}
      </CustomTabs>
    </Drawer>
  );
};
