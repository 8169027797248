import { Box, Typography, styled } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import logo from "../../assets/logo.svg";
import useDispatchActions from "../../hooks/useDispatchActions.js";
import { ROUTE_PATHS } from "../../routes/routePaths.js";
import { AppBarLayout, AppLogo, AppProfile, CommonAvatar } from "../../styles";
import { removeAllCookies } from "../../utils/cookies.js";
import { RightMenu } from "./RightMenu";

const CustomTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  color: theme?.palette?.primary?.main,
  fontSize: 14,
}));

const StyledLogo = styled("img")(({ theme }) => {
  return {
    height: "40px",
    width: "40px",
  };
});

const StyledTitle = styled("div")(({ theme }) => {
  return {
    fontFamily: "Roboto",
    fontSize: "24px",
    color: theme?.palette?.primary?.main,
    fontWeight: "600",
  };
});

const PortalContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginLeft: "auto",
  height: "100%",
  overflow: "auto",
  marginRight: "5%",
  cursor: "pointer",
  "&::-webkit-scrollbar": {
    width: "5px",
    height: "5px",
  },
});

export const AppBar = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const {
    dispatchSeeds,
    dispatchUserInfo,
    dispatchRole,
    dispatchPortal,
    dispatchIsAdmin,
  } = useDispatchActions();
  const {
    profileImageUrl = "",
    name = "",
    firstName = "",
    lastName = "",
    isAdmin = 0,
  } = useSelector((state) => state?.userInfo);
  const navigate = useNavigate();
  const handleClose = () => setAnchorEl(null);
  const redirect = (routePath) => {
    if (routePath === ROUTE_PATHS.LOGIN) {
      removeAllCookies();
      dispatchSeeds("clear");
      dispatchUserInfo({});
      dispatchPortal(null);
      dispatchIsAdmin(false);
      dispatchRole({});
    }
    navigate(routePath);
    handleClose();
  };
  return (
    <AppBarLayout
      position="fixed"
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      <AppLogo>
        <StyledLogo src={logo} />
        <StyledTitle>CRM</StyledTitle>
      </AppLogo>
      <PortalContainer></PortalContainer>
      <AppProfile onClick={handleClick}>
        <Box className="appProfileDetails">
          <CustomTypography fontSize={18} fontWeight={500}>
            {name || `${firstName} ${lastName || ""}` || "Anonymous"}
          </CustomTypography>
          <CustomTypography sx={{ fontSize: "12px" }}>
            {isAdmin ? "Admin" : "User"}
          </CustomTypography>
        </Box>
        <CommonAvatar src={profileImageUrl} alt="IMG" />
      </AppProfile>
      <RightMenu
        anchorEl={anchorEl}
        handleClose={handleClose}
        open={Boolean(anchorEl)}
        redirect={redirect}
      />
    </AppBarLayout>
  );
};
