import { object, string } from "yup";

export const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export const UPPER_CASE = /(?=.*?[A-Z])/;
export const LOWER_CASE = /(?=.*?[a-z])/;
export const NUMBER = /(?=.*?[0-9])/;
export const SPECIAL_CHARACTER =
  /(?=.*?[!@#$%^&*()\-_=+\\[\]{}\\|;:'",.<>\\/?`~])/;

export const validationSchema = object({
  mobileNo: string()
    .trim()
    .required("Mobile Number is required")
    .length(10, "Mobile number be 10 digits")
    .test("isZero", (value, context) => {
      if (!!value && Number(value) === 0)
        return context.createError({ message: "Invalid mobile number" });
      return true;
    }),

  password: string().trim().required("Password is required"),
});

export const forgetPasswordValidation = (data) =>
  object({
    ...(data
      ? {
          otp: string()
            .trim()
            .required("OTP is required")
            .max(6, "OTP must be 6 digits"),
        }
      : {
          mobileNo: string()
            .trim()
            .length(10, "Mobile number be 10 digits")
            .test("isZero", (value, context) => {
              if (!!value && Number(value) === 0)
                return context.createError({
                  message: "Invalid mobile number",
                });
              return true;
            }),
        }),
  });

export const setPasswordValidation = object({
  password: string().trim().required("Password is required"),
  // .matches(UPPER_CASE, "Password must contain at least one uppercase letter")
  // .matches(LOWER_CASE, "Password must contain at least one lowercase letter")
  // .matches(NUMBER, "Password must contain at least one number")
  // .matches(
  //   SPECIAL_CHARACTER,
  //   "Password must contain at least one special character"
  // )
  // .min(6, "Password must be at least 6 characters long")
  // .max(25, "Password cannot have more than 25 characters"),

  confirmPassword: string()
    .trim()
    .required("Confirm password is required")
    .test(
      "confirmPassword",
      "Confirm password should be same",
      (value, context) => {
        if (!!value && value !== context?.parent?.password) return false;
        return true;
      }
    ),
});
