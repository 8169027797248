import { Accordion, AccordionDetails } from "@mui/material";
import { useEffect, useState } from "react";
import { CustomTab } from "./SideBarNavigators";
import WithCondition from "./WithCondition";

import { ExpandMore } from "@mui/icons-material";
import { AccordionSummary, Box, Button, styled } from "@mui/material";
import { useLocation } from "react-router-dom";
import CustomToolTip from "./CustomToolTip";

export const EmptyImage = styled("img")({
  width: 100,
  height: 100,
});
export const ScreenContainer = styled(Box)({
  maxWidth: "calc(100vw - 366px)",
  minWidth: "calc(100% - 386px)",
  maxHeight: "calc(100vh - 180px)",
  overflow: "scroll",
  marginTop: 40,
  marginInline: 40,
  paddingBottom: 40,
  position: "relative",
  "&::-webkit-scrollbar": {
    width: "8px",
    height: "5px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#6E6E6E60",
    borderRadius: 5,
  },
  "&::-webkit-scrollbar-thumb:hover": {
    background: "#555",
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "#F5F5F5",
  },
});
export const EmptyContainer = styled(Box)({
  width: "100%",
  height: "calc(100vh - 140px)",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: 16,
});
export const Clickable = styled(Box)(({ top, left, height, width }) => ({
  position: "absolute",
  top: `${top}px`,
  left: `${left}px`,
  height: `${height}px`,
  width: `${width}px`,
  backgroundColor: "red",
  opacity: "0.5",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "blue",
  },
}));
export const InternalSideBarContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  border: "1px solid",
  borderTop: "none",
  paddingTop: 10,
  paddingBottom: "auto",
  width: "20vw",
  minWidth: "280px",
  maxWidth: "282px",
  height: "calc(100vh - 140px)",
  overflowY: "scroll",
  "&::-webkit-scrollbar": {
    width: "5px",
    height: "5px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#6E6E6E60",
    borderRadius: 5,
  },
  "&::-webkit-scrollbar-thumb:hover": {
    background: "#555",
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "#F5F5F5",
  },
});
export const CustomSideBar = styled(Button)(({ theme, isactive }) => ({
  margin: "5px auto",
  padding: "auto",
  border: "1px solid",
  borderColor: theme?.palette?.primary?.main,
  width: "88%",
  height: 46,
  textAlign: "left",
  justifyContent: "left",
  borderRadius: 3,
  paddingLeft: 16,
  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  color: isactive ? "#fff" : theme?.palette?.primary?.main,
  backgroundColor: isactive ? theme.palette.primary?.main : "#fff",
  "& .MuiTypography-root": {
    fontSize: 16,
  },
  "&:hover": {
    color: isactive ? "#fff" : theme?.palette?.primary?.main,
    backgroundColor: isactive ? theme.palette.primary?.main : "#fff",
  },
}));

export const CustomAccordionSummary = styled(AccordionSummary)(
  ({ theme, isactive }) => ({
    margin: "5px auto",
    padding: "auto",
    border: "1px solid",
    borderColor: theme?.palette?.primary?.main,
    width: "88%",
    height: 40,
    textAlign: "start",
    borderRadius: 3,
    fontFamily: "Rubik",
    textTransform: "uppercase",
    color: isactive ? "#fff" : theme?.palette?.primary?.main,
    backgroundColor: isactive ? theme.palette.primary?.main : "#fff",
    "& .MuiTypography-root": {
      fontSize: 14,
      fontWeight: 700,
    },
    "&:hover": {
      color: isactive ? "#fff" : theme?.palette?.primary?.main,
      backgroundColor: isactive ? theme.palette.primary?.main : "#fff",
    },
  })
);

export const popoverItemStyle = {
  width: 200,
  height: 50,
  display: "flex",
  alignItems: "center",
  padding: "0.3rem 0.8rem",
  borderBottom: "1px solid #eeeeee",
  cursor: "pointer",
};
export const popoverStyle = {
  marginLeft: 18,
};

export const CustomAccordion = ({
  menuItem = [],
  onClick = () => {},
  drawerOpen,
}) => {
  const { pathname } = useLocation();

  const [expanded, setExpanded] = useState(false);
  const currentPage = pathname
    .split("/")
    .slice(1, menuItem?.level + 1)
    .join("/");

  const isActive = currentPage === menuItem?.value;

  useEffect(() => {
    setExpanded(isActive);
  }, []); //eslint-disable-line

  return menuItem?.children?.length ? (
    <Accordion
      key={menuItem?.label ?? menuItem?.name}
      disableGutters
      sx={{
        boxShadow: "none",
        backgroundColor: "primary.main",
        width: "100%",
        paddingLeft: menuItem?.level === 2 ? "25px" : "auto",
        minWidth: "260px",
      }}
      expanded={drawerOpen && expanded}
    >
      <CustomAccordionSummary
        expandIcon={
          <ExpandMore
            sx={{ color: isActive ? "primary.main" : "background.paper" }}
          />
        }
        sx={{
          margin: 0,
          padding: "5px",
          width: "auto",
          backgroundColor: isActive ? "common.white" : "primary.main",
          "&:hover": {
            backgroundColor: isActive ? "common.white" : "primary.main",
          },
        }}
        onClick={(e) => {
          setExpanded((prev) => !prev);
          onClick(menuItem)(e);
        }}
      >
        <CustomTab
          label={menuItem?.label ?? menuItem?.name}
          id={menuItem?.value}
          key={menuItem?.label}
          icon={
            <CustomToolTip
              title={drawerOpen ? "" : menuItem?.name ?? menuItem?.label}
            >
              {menuItem?.Icon ?? null}
            </CustomToolTip>
          }
          iconPosition="start"
          sx={{ paddingLeft: drawerOpen ? "10px" : "28px" }}
          active={menuItem?.level === 1 && isActive ? 1 : 0}
          isactive={menuItem?.level !== 1 && isActive ? 1 : 0}
        />
      </CustomAccordionSummary>
      <WithCondition isValid={menuItem?.children?.length}>
        <AccordionDetails
          sx={{
            padding: 0,
            paddingLeft: menuItem?.level === 1 ? 3 : "5px",
            display: "flex",
            flexDirection: "column",
            marginTop: "8px",
          }}
        >
          {menuItem?.children?.map((item, itemIdx) => (
            <CustomAccordion
              menuItem={item}
              onClick={onClick}
              key={item?.name + itemIdx}
            />
          ))}
        </AccordionDetails>
      </WithCondition>
    </Accordion>
  ) : (
    <CustomTab
      label={menuItem?.level === 1 ? menuItem?.label : menuItem?.name}
      id={menuItem?.value}
      value={menuItem?.value ?? menuItem?.name}
      key={menuItem?.label}
      onClick={(e) => {
        onClick(menuItem)(e);
      }}
      icon={
        <WithCondition isValid={menuItem?.level === 1}>
          <CustomToolTip
            title={drawerOpen ? "" : menuItem?.name ?? menuItem?.label}
          >
            {menuItem?.Icon ?? null}
          </CustomToolTip>
        </WithCondition>
      }
      iconPosition="start"
      secondary={menuItem?.level === 2 ? 1 : 0}
      active={menuItem?.level === 1 && isActive ? 1 : 0}
      isactive={menuItem?.level !== 1 && isActive ? 1 : 0}
    />
  );
};
