import jwt_decode from "jwt-decode";

import { getCookie } from "../utils/cookies";

export const apiConfig = () => {
  const isTokenExpires = (
    getCookie("token") ? jwt_decode(getCookie("token")) : ""
  )?.exp;
  const isRefreshTokenExpires = (
    getCookie("refreshToken") ? jwt_decode(getCookie("refreshToken")) : ""
  )?.exp;
  const currentTime = Math.floor(new Date().getTime() / 1000);

  return { isTokenExpires, isRefreshTokenExpires, currentTime };
};
