import { Grid } from "@mui/material";

import { FormLayout, StyledFormContainer } from "../../styles";
import { BackNavigator } from "./BackNavigator";
import WithCondition from "./WithCondition";

const FormWrapper = ({
  children,
  navigateTo = "",
  title = "",
  rowSpacing,
  columnSpacing,
  disableBackNavigator,
  formWidth = "70%",
  customTitle,
  disableModes,
  disableBack,
  containerStyle,
  additionalComponent,
  sectionStyle = {},
}) => (
  <>
    <WithCondition isValid={!disableBackNavigator}>
      <BackNavigator
        title={title}
        navigateTo={navigateTo}
        customTitle={customTitle}
        disableModes={disableModes}
        disableBack={disableBack}
        additionalComponent={additionalComponent}
      />
    </WithCondition>

    <FormLayout>
      <StyledFormContainer width={formWidth} sx={containerStyle}>
        <Grid
          container
          rowSpacing={rowSpacing || 3}
          columnSpacing={columnSpacing || 3}
          sx={sectionStyle}
        >
          {children}
        </Grid>
      </StyledFormContainer>
    </FormLayout>
  </>
);

export default FormWrapper;
