import { useTheme } from "@emotion/react";
import { FormHelperText } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { styled } from "@mui/system";
import ScreenHelp from "../ScreenHelp";
import WithCondition from "../WithCondition";

const RadioBox = styled("div")(({ rowBreak }) => ({
  display: "flex",
  alignItems: rowBreak ? "left" : "center",
  flexDirection: rowBreak && "column",
}));

export const CustomRadioButton = ({
  inputValues,
  name,
  onChange,
  onBlur,
  value = "",
  labelStyle,
  label,
  style,
  rowBreak,
  disabled,
  defaultValue,
  isViewMode,
  accessor,
  touched,
  errors,
  customHelpertext,
  help,
}) => {
  const theme = useTheme();
  return (
    <FormControl style={style}>
      <RadioBox rowBreak={rowBreak} style={{ justifyContent: "space-between" }}>
        <span
          style={{
            marginRight: "20px",
            fontSize: "18px",
            fontWeight: 500,
            color: theme.palette.text.secondary,
            ...labelStyle,
          }}
        >
          {label}
          <WithCondition isValid={help}>
            <span>
              <ScreenHelp
                htmlText={help}
                isField
                buttonStyle={{
                  top: 0,
                  bottom: 2,
                  // paddingRight: "12px",
                  width: "25px",
                  height: "25px",
                }}
                style={{ marginRight: "10px" }}
              />
            </span>
          </WithCondition>
        </span>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          name={name}
          onChange={!isViewMode ? onChange : () => {}}
          onBlur={onBlur}
          error={Boolean(customHelpertext || (touched && errors)).toString()}
          value={value || ""}
          defaultValue={defaultValue}
          row
        >
          {inputValues?.map((option, i) => {
            return (
              <FormControlLabel
                value={option[accessor] || option?.id || option?.name}
                control={
                  <Radio
                    onChange={(e) => {
                      e.target.blur();
                    }}
                  />
                }
                label={option?.name || option?.label}
                key={i}
                name={name}
                disabled={disabled}
              />
            );
          })}
        </RadioGroup>
      </RadioBox>
      <FormHelperText error>
        {customHelpertext || (touched && errors)}
      </FormHelperText>
    </FormControl>
  );
};
