export const sortedValues = (column, value) => {
  const getObj = column?.find((item) => item?.accessor === value?.[0]?.id);
  let output = [];
  output.push({
    column: getObj?.accessor || "",
    order: value?.[0]?.desc ? "desc" : "asc",
  });
  return output;
};

export const createFilter = (field, value) => ({
  type: "eq",
  field,
  value,
});

export const getSeedIdByName = (seeds = [], name = "") => {
  return seeds?.find((seed) => seed?.name === name)?.id;
};

export const getSeedIdByCode = (seeds = [], code = "") => {
  return seeds?.find((seed) => seed?.code === code)?.id || "";
};

export const getSeedNameById = (seeds = [], id = "") => {
  return seeds?.find((seed) => seed?.id === id)?.name || "";
};

export const getSeedCodeById = (seeds = [], id = "") => {
  return seeds?.find((seed) => seed?.id === id)?.code || "";
};

export const getNeededValues = (values, initialValues) => {
  return Object.keys(initialValues).reduce((formatted, key) => {
    return {
      ...formatted,
      [key]: values[key] ?? initialValues[key],
    };
  }, {});
};

export const getValidValues = (
  values,
  withArray,
  include = [],
  nullables = []
) => {
  const entries = [...Object.keys(values), ...include] || [];
  const validData = entries.reduce((validated, key) => {
    if (nullables?.includes(key)) return { ...validated, [key]: values[key] };
    if (Array.isArray(values[key])) {
      return {
        ...validated,
        ...((values[key]?.length || withArray) && { [key]: values[key] }),
      };
    }
    if (
      typeof values[key] === "boolean" ||
      !!values[key] ||
      typeof values[key] === "number"
    ) {
      if (typeof values[key] === "string") {
        return { ...validated, [key]: values[key].replace(/\s+/g, " ").trim() };
      }
      return { ...validated, [key]: values[key] };
    }
    return validated;
  }, {});
  return validData;
};

export const getFilter = (name, value, isViewMode) =>
  !isViewMode
    ? {
        filters: [
          {
            type: "eq",
            field: name,
            value,
          },
        ],
      }
    : {};

export const getEmployeeLabel = (option) =>
  `${option?.name}${
    option?.empReferenceId ? " - " + option?.empReferenceId : ""
  }`;

export const getHtmlHelps = (fieldHelpText = "[]") => {
  const fieldHelps = JSON.parse(fieldHelpText || "[]") || [];
  const getByObj = (fieldHelps ?? [])?.reduce(
    (acc, item) => ({ ...acc, [item?.key]: item?.value }),
    {}
  );
  return getByObj;
};

export const hexToRgb = (hex = "") => {
  // Remove the leading # if it's there
  hex = hex.replace(/^#/, "");

  // Parse r, g, b values
  let bigint = parseInt(hex, 16);
  let r = (bigint >> 16) & 255;
  let g = (bigint >> 8) & 255;
  let b = bigint & 255;

  return { r, g, b };
};

export const lightenColor = (rgb, factor) => {
  return {
    r: Math.min(255, Math.floor(rgb.r + (255 - rgb.r) * factor)),
    g: Math.min(255, Math.floor(rgb.g + (255 - rgb.g) * factor)),
    b: Math.min(255, Math.floor(rgb.b + (255 - rgb.b) * factor)),
  };
};

export const rgbToRgba = (rgb, alpha) =>
  `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${alpha})`;

export const hexToLightenedRgba = (
  hex = "",
  alpha = 1,
  lightenFactor = 0.2
) => {
  const rgb = hexToRgb(hex);
  const lightenedRgb = lightenColor(rgb, lightenFactor);
  return rgbToRgba(lightenedRgb, alpha);
};
