import { Quill } from "react-quill";

import StatusBadge from "components/shared/StatusBadge";
import { OptionsContainer } from "../components/shared";

// Regex
export const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export const PAN_PATTERN =
  /^([A-Z]{3})([P | C | A | F | H | T])([A-Z])([0-9]{4})([A-Z])$/;
export const GST_PATTERN =
  /^([0-9]{2})([A-Z]{3})([P | C | A | F | H | T])([A-Z])([0-9]{4})([A-Z])([1-9A-Z]{1})([Z]{1})([0-9A-Z]{1})$/;
export const LOWER_CASE = /(?=.*?[a-z])/;
export const UPPER_CASE = /(?=.*?[A-Z])/;
export const NUMBER = /(?=.*?[0-9])/;
export const SPECIAL_CHARACTER =
  /(?=.*?[!@#$%^&*()\-_=+\\[\]{}\\|;:'",.<>\\/?`~])/;
export const LATITUDE_REGEX =
  /^([-+]?)(90(\.0+)?|(([0-9]|[0-8][0-9])(\.\d+)?))$/;
export const LONGITUDE_REGEX =
  /^([-+]?)(180(\.0+)?|((1[0-7][0-9]|[0-9]{1,2})(\.\d+)?))$/;

// Button Labels
export const NEW = "NEW";
export const CANCEL = "Cancel";
export const CLEAR_FILTER = "Clear Filter";
export const APPLY = "Apply";
export const SUBMIT = "Submit";
export const UPDATE = "Update";
export const CONFIRM = "CONFIRM";
export const OKAY = "OKAY";
export const ADD = "Add";
export const SKIP = "Skip";

//Seeds
export const IMAGE = "image";
export const VIDEO = "video";
export const PDF = "pdf";
export const DOC_TO_PDF = "docx-to-pdf";

export const ROLES = {
  SUPER_ADMIN: "super-admin",
  ADMIN: "admin",
  ORGANIZATION_ADMIN: "organization-admin",
  BRANCH_ADMIN: "branch-admin",
};

export const CODES = {
  ACTIVE: "active",
  IN_ACTIVE: "in-active",
  QUALIFICATION_TEN: "ten",
  QUALIFICATION_TWELVE: "twelve",
  PENDING: "pending",
  REJECTED: "rejected",
  APPROVED: "approved",
  ADMIN_PORTAL: "admin_portal",
  USER_PORTAL: "user_portal",
  ATTENDANCE_PORTAL: "attendance_portal",
  EMPLOYEE_PORTAL: "employee_portal",
  SALARY_PORTAL: "salary_portal",
  SELECTED_DESIGNATION: "selected-designation",
  SELECTED_EMPLOYEES: "selected-employees",
  ALL_EMPLOYEES: "all-employees",
  OFFICE_LOCATION: "office",
  CLIENT_LOCATION: "client-place",
  REMOTE_LOCATION: "remote",
  HYBRID_LOCATION: "hybrid",
  FOOD_PORTAL: "food_portal",
  EXPENSES: "expenses",
  DATE_RANGE: "date-range",
  MAIL: "mail",
  WHATSAPP: "whatsapp",
};

export const module = {
  divider: true,
  // syntax: true,
  clipboard: {
    matchVisual: false,
  },
  blotFormatter: {},
  toolbar: [
    ["bold", "italic", "underline", "strike"],
    ["divider"],
    // toggled buttons
    ["blockquote", "code-block"],
    [{ header: 1 }, { header: 2 }], // custom button values
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
    [{ direction: "rtl" }], // text direction

    [{ size: ["small", false, "large", "huge"] }], // custom dropdown
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ font: [] }],
    [
      { align: "" },
      { align: "center" },
      { align: "right" },
      { align: "justify" },
    ],
    ["clean"], // remove formatting button
    ["link", "image", "video"],
    ["hr"],
  ],
  imageResize: {
    parchment: Quill.import("parchment"),
    modules: ["Resize", "DisplaySize"],
  },
};

export const statusColumns = [
  {
    Header: "Status",
    width: 200,
    accessor: "statusId",
    Cell: (props) => (
      <StatusBadge value={props?.row?.original?.status?.name || ""} />
    ),
  },
  {
    Header: "Date",
    accessor: "effectiveDate",
    width: 150,
    Cell: (props) => (
      <OptionsContainer>{props?.value || "N/A"}</OptionsContainer>
    ),
  },
  {
    Header: "Reason",
    accessor: "deactivationReason",
    width: 150,
    Cell: (props) => <OptionsContainer>{props?.value || "-"}</OptionsContainer>,
  },
];

export const PARAMETER_OPTION = [
  { id: "1", label: "Yes" },
  { id: "0", label: "No" },
];

export const defaultRoles = ["admin", "super-admin"];

export const defaultPortals = [
  { name: "Admin Portal", code: CODES.ADMIN_PORTAL },
  { name: "User Portal", code: "user_portal" },
  { name: "Attendance Portal", code: "attendance_portal" },
  { name: "Food Portal", code: "food_portal" },
  { name: "Expenses", code: "expenses" },
];

export const PALETTE_KEYS = {
  primary: {
    main: "primary.main",
  },
  background: {
    default: "background.default",
  },
  text: {
    primary: "text.primary",
  },
};
export const STATUS_COLORS = {
  success: "lightgreen",
  pending: "orange",
  error: "",
};

export const RADIAN = Math.PI / 180;
export const commonColors = [
  "#005B41",
  "#2D3250",
  "#F6B17A",
  "#7E6363",
  "#9290C3",
];
export const dashboardColors = [
  "#892A86",
  "#FE674C",
  "#F9D600",
  "#1976D3",
  "#0D8A78",
  "#FF0080",
];

export const foodDashboardColors = [
  "#b33b3b",
  "#3bb33b",
  "#3b3bb3",
  "#b3b33b",
  "#3bb3b3",
  "#b33bb3",
  "#b36b3b",
  "#6b3bb3",
  "#b33b6b",
  "#6bb33b",
  "#3bb36b",
  "#b33bb3",
  "#b39b3b",
  "#3b3bb3",
  "#b35959",
  "#8b8b3b",
  "#3b8b8b",
  "#b38b3b",
  "#b33b8b",
  "#3bb38b",
];

export const SCREEN_NUMBERS = {
  //USER PORTAL

  DASHBOARD: "USER001",
  ORG_SETUP: "USER002",
  ORG_BRANCHES: "USER003",
  ORG_DEPT: "USER004",
  ORG_DESIGNATION: "USER005",
  ORG_GRADES: "USER006",

  EMP_PERSONAL_DETAILS: "USER007",
  EMP_QUALIFICATIONS: "USER008",
  EMP_ADDRESS: "USER009",
  EMP_BANK_DETAILS: "USER010",
  EMP_WORK_EXP: "USER011",
  EMP_FAMILY_DETAILS: "USER012",
  EMP_NOMINEE_DETAILS: "USER013",
  EMP_EXTRA_ACTIVITIES: "USER014",
  EMP_HEALTH_RECORDS: "USER015",

  EMP_BRANCH_TRN: "USER016",
  EMP_EMP_TYPE_TRN: "USER017",
  EMP_DEPT_TRN: "USER018",
  EMP_DESIGNATION_TRN: "USER019",
  EMP_GRADE_TRN: "USER020",
  EMP_REPORTING_TRN: "USER021",
  EMP_HR_TRN: "USER022",

  EXIT_PROCESS: "USER023",

  PORTAL_MASTER: "USER024",
  APP_ROLE_ACCESS: "USER025",
  EMP_ACCESS: "USER026",

  MAIL_MASTER: "USER027",
  MAIL_SCHEDULE: "USER028",

  CLIENT_MASTER: "USER029",
  CLIENT_ASSIGN: "USER030",

  //ATTENDANCE PORTAL

  ATTN_DASHBOARD: "ATTN001",
  SHIFT_MASTER: "ATTN002",
  SHIFT_ASSIGNATIONS: "ATTN003",

  ATTENDANCE: "ATTN004",
  ORG_HOLIDAYS: "ATTN005",
  LEAVE_PLANNING: "ATTN006",
  LEAVE_MAPPING: "ATTN007",
  APPLY_LEAVE: "ATTN008",
  PRE_COMPENSATION: "ATTN009",
  APPLY_COMPENSATION: "ATTN010",

  WEEKEND_PLANNING: "ATTN011",
  WEEKEND_MAPPING: "ATTN012",

  GLOBAL_PARAMETER: "ATTN013",
  PARAMETER_PLANS: "ATTN014",
  PLANS_MAPPING: "ATTN015",
  INDIVIDUAL_PARAMETER: "ATTN016",

  //EMPLOYEE PORTAL
  EMPLOYEE_HOME: "EMP001",
  EMPLOYEE_DASHBOARD: "EMP002",
  MY_ATTENDANCE: "EMP003",
  MY_LEAVES: "EMP004",
  EMP_PRE_COMPENSATION: "EMP005",
  EMP_APY_COMPENSATION: "EMP006",
  MY_TIME_SHEET: "EMP007",
  MY_TEAM: "EMP008",

  LEAVE_APPROVALS: "EMP009",
  TIMESHEET_APPROVALS: "EMP010",
  PRE_COMP_APPROVALS: "EMP011",
  LEAVE_COMP_APPROVALS: "EMP012",
  ATTENDANCE_APPROVALS: "EMP013",
};

export const WEEK_DAYS = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
