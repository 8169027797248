import {
  Accordion,
  Badge,
  Box,
  Grid,
  ToggleButton as MuiToggleButton,
  Typography,
  styled,
} from "@mui/material";

export const Heading = styled(Typography)(({ theme }) => ({
  fontSize: "1.5rem",
  color: theme.palette.primary.main,
  fontFamily: "Rubik",
  textAlign: "center",
  fontWeight: "bold",
  position: "sticky",
  top: "0px",
  background: theme.palette.background.default,
  // backgroundColor: "inherit",
  padding: "25px 0px",
  width: "100%",
  zIndex: "5",
}));

export const Title = styled(Typography)(({ theme }) => ({
  fontSize: "1.25rem",
  fontFamily: "lato",
  color: theme.palette.primary.main,
  fontWeight: "bold",
}));

export const StyledKey = styled(Typography)(({ theme }) => ({
  fontSize: "1.1rem",
  fontFamily: "Roboto",
}));

export const StyledValue = styled(Typography)(({ valid }) => ({
  fontWeight: "bold",
  fontSize: "1.1rem",
  color: "#2196f3",
  textDecoration: valid === "true" ? "underline" : "none",
  cursor: "pointer",
}));

export const ReportListContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "15px",
  padding: "0px 15px 15px 15px",
});

export const StyledCard = styled(Grid)({
  boxShadow:
    "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
  borderRadius: "4px",
  border: "1px solid #a2befb",
  padding: "0px 15px 15px 15px",
  height: "auto",
  width: "100%",
  boxSizing: "border-box",
});

export const CardContainer = styled("div")(({ theme }) => ({
  // width: "50%",
  height: "auto",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  gap: "20px",
  minWidth: "400px",
  position: "relative",
}));

export const Container = styled("div")(({ theme }) => ({
  height: "100%",
  width: "100%",
  padding: "0px 40px 25px 40px",
  display: "flex",
  flexDirection: "column",
  boxSizing: "border-box",
  overflowY: "auto",
  "&::-webkit-scrollbar": {
    width: "5px",
    height: "5px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#6E6E6E60",
    borderRadius: 5,
  },
  "&::-webkit-scrollbar-thumb:hover": {
    background: "#555",
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "#F5F5F5",
  },
}));

export const ReportsContainer = styled("div")(({ theme }) => ({
  width: "90%",
  height: "auto",
  // display: "flex",
  // justifyContent: "space-around",
}));
export const FilteredContainer = styled(Box)(({ theme }) => ({
  maxWidth: "100%",
  flexWrap: "wrap",
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "space-between",
  width: "100%",
  [theme.breakpoints.down("md")]: {
    flexWrap: "wrap",
  },
}));
export const DetailedLayout = styled(Box)({
  overflow: "scroll",
  overflowX: "hidden",
  maxHeight: "calc(100vh - 156px)",
  "&::-webkit-scrollbar": {
    width: "5px",
    height: "5px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#6E6E6E60",
    borderRadius: 5,
  },
  "&::-webkit-scrollbar-thumb:hover": {
    background: "#555",
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "#F5F5F5",
  },
});
export const DetailedTitleContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  paddingLeft: "80px",
  position: "sticky",
  top: 0,
  left: 0,
  zIndex: 2,
  paddingBlock: "8px",
  backgroundColor: theme.palette.background.default,
}));
export const DetailedTitle = styled(Box)({
  width: "20%",
  textAlign: "end",
  fontSize: "18px",
  fontWeight: 500,
});
export const NoData = styled(Box)({
  fontSize: "18px",
  textAlign: "center",
  marginBlock: "24px",
});
export const DetailedAccordion = styled(Accordion)({
  marginTop: 24,
  ".MuiAccordionDetails-root": {
    paddingInline: 0,
  },
  ".MuiAccordion-gutters": {
    margin: 0,
  },
});
export const MonthName = styled(Typography)({
  width: "50px",
  padding: 8,
  fontSize: "19px",
});
export const DetailedRow = styled(Box)({
  display: "flex",
  alignItems: "center",
  paddingInline: "16px",
  paddingBlock: "5px",
});
export const DateBadge = styled(Badge)(({ theme }) => ({
  ".MuiSvgIcon-root": {
    fontSize: "30px",
  },
  ".MuiBadge-badge": {
    marginTop: "19px",
    marginRight: "16px",
    fontSize: "10px",
    fontWeight: 600,
    backgroundColor: "transparent",
    color: theme.palette.primary.main,
  },
}));
export const DetailedRowDetails = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  paddingInline: "14px",
  width: "100%",
});
export const DetailedRowData = styled(Typography)(({ valid }) => ({
  width: "20%",
  textAlign: "end",
  fontWeight: "bold",
  fontSize: "1.1rem",
  color: "#2196f3",
  textDecoration: valid === "true" ? "underline" : "none",
  cursor: "pointer",
}));
export const switchStyle = {
  position: "absolute",
  right: 0,
  bottom: 4,
};
export const switchThumbStyle = {
  ".MuiSwitch-thumb": {
    height: "15px",
    width: "15px",
    marginTop: "1px",
  },
  ".MuiButtonBase-root": {
    height: "36px",
  },
  ".MuiSwitch-track": {
    width: "30px",
    height: "13px",
  },
};
export const FiltersContainer = styled(Box)(({ theme, align }) => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  gap: 1,
  borderRadius: 2,
}));
export const FilterSection = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  gap: 8,
  borderRadius: 6,
  border: `1px solid ${theme.palette.text.secondary}`,
  boxShadow:
    "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
}));
export const FilterButton = styled(Typography)(({ isactive, theme }) => ({
  padding: "5px",
  fontSize: "17px",
  cursor: "pointer",
  paddingInline: "8px",
  borderRadius: "8px",
  color:
    isactive === "true"
      ? theme.palette.background.default
      : theme.palette.text.primary,
  backgroundColor:
    isactive === "true"
      ? theme.palette.text.secondary
      : theme.palette.background.default,
}));

export const ProductiveDetails = styled(Box)(({ top, right }) => ({
  width: "100%",
  position: "absolute",
  display: "flex",
  justifyContent: "center",
  top: top ?? "0%",
  right: right ?? "-50%",
  textAlign: "center",
}));

export const ToggleButton = styled(MuiToggleButton)(({ theme }) => ({
  padding: "5px 7px",
  height: "36px",
  textTransform: "none",
  "&.Mui-selected": {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    ".MuiSvgIcon-root": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    ":hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
  },
}));
