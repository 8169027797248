import { Divider } from "@mui/material";
import { PALETTE_KEYS } from "../../constants";

export const DividerLine = ({ color = PALETTE_KEYS.primary.main, gap }) => (
  <Divider
    style={{
      background: color,
      borderRadius: 20,
      height: 2,
      margin: gap ? "30px 0" : "",
    }}
  />
);
