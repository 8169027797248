import { Badge, Box, Typography } from "@mui/material";

const StatusBadge = ({ value }) => {
  const status =
    value === "Approved" || value === "Active" || value === "Positive"
      ? "success"
      : value === "Inactive" || value === "Rejected" || value === "Negative"
      ? "error"
      : "warning";
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Badge color={status} variant="dot" invisible={false}></Badge>
      <Typography
        sx={{
          borderRadius: "25px",
          paddingInline: "10px",
          fontSize: "14px",
          fontStyle: "italic",
        }}
      >
        {value}
      </Typography>
    </Box>
  );
};

export default StatusBadge;
