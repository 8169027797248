import { Box, Grid, Typography, styled } from "@mui/material";

import user from "../../assets/user_copy.png";
import WithCondition from "./WithCondition";

const StyledImage = styled("img")({
  width: 175,
  height: 200,
  objectFit: "cover",
});

const DetailSection = styled(Box)({
  display: "flex",
});

const Title = styled(Typography)({
  width: 180,
  display: "flex",
  justifyContent: "space-between",
  marginRight: 16,
  fontWeight: 700,
});

const employeeDetailList = [
  { accessor: "employeeName", Header: "Employee Name", default: true },
  { accessor: "empReferenceId", Header: "Employee ID", default: true },
  { accessor: "designation", Header: "Designation", default: true },
  { accessor: "organization", Header: "Organization", default: true },
  { accessor: "branch", Header: "Current Branch", default: true },
  { accessor: "duration", Header: "Duration" },
  { accessor: "shift", Header: "Shift" },
  { accessor: "shiftStartDate", Header: "Shift Start Date" },
  { accessor: "shiftEndDate", Header: "Shift End Date" },
];

function EmployeeDetail({
  employeeDetail,
  disableProfile = false,
  moreDetails = [],
  additionalDetails = [],
}) {
  if (!employeeDetail?.id) return null;

  return (
    <>
      <Grid item xs={12}>
        <Grid container>
          <WithCondition isValid={!disableProfile}>
            <Grid item xs={4}>
              <StyledImage
                src={employeeDetail?.profileImageUrl || user}
                onError={(e) => (e.target.src = user)}
                alt="Profile picture"
              />
            </Grid>
          </WithCondition>

          <Grid item xs={8}>
            {employeeDetailList.map((item, key) => (
              <WithCondition
                isValid={
                  !!employeeDetail[item?.accessor] &&
                  (item?.default || moreDetails?.includes(item?.accessor))
                }
              >
                <DetailSection
                  key={key + item?.accessor}
                  sx={{ display: "flex" }}
                >
                  <Title>{`${item?.Header} : `}</Title>
                  <Typography>{employeeDetail[item?.accessor]}</Typography>
                </DetailSection>
              </WithCondition>
            ))}
            {additionalDetails.map((item, key) => (
              <DetailSection
                key={key + item?.accessor}
                sx={{ display: "flex" }}
              >
                <Title>{`${item?.Header} : `}</Title>
                <Typography>
                  {item?.formatter
                    ? item?.formatter(employeeDetail[item?.accessor])
                    : employeeDetail[item?.accessor]}
                </Typography>
              </DetailSection>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default EmployeeDetail;
