import { ThemeProvider } from "@emotion/react";
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useNavigate } from "react-router-dom";

import { getApiServices } from "api/api.js";
import { apiPaths } from "api/apiPaths.js";
import { CustomLoader, NotFound, UserNotification } from "components/shared";
import useDispatchActions from "hooks/useDispatchActions.js";
import { setUserInfo } from "redux/slice";
import { ProtectedRoute } from "routes/ProtectedRoute";
import { loginRoutes } from "routes/index.js";
import { routeMapping } from "routes/routeMapping";
import { ROUTE_PATHS } from "routes/routePaths";
import { darkTheme, theme } from "styles/theme";
import { getCookie, removeCookie } from "utils/cookies.js";
import routes from "./../routes";

function Root() {
  const { notifyError } = useDispatchActions();
  const navigate = useNavigate();
  const snackBar = useSelector((state) => state?.snackBar);
  const isLoading = useSelector((state) => state?.isLoading);
  const userInfo = useSelector((state) => state?.userInfo);
  const currentMode = useSelector((state) => state?.currentMode);
  const dispatch = useDispatch();

  const queryClient = new QueryClient({
    queryCache: new QueryCache({
      onError: (error) => {
        if (typeof error?.response?.data?.error === "string") {
          notifyError(error?.response?.data?.error);
        } else {
          notifyError("Something went wrong. Please try again!");
        }
      },
    }),
    mutationCache: new MutationCache({
      onError: (error) => {
        if (typeof error?.response?.data?.error === "string") {
          notifyError(error?.response?.data?.error);
        } else {
          notifyError("Something went wrong. Please try again!");
        }
      },
    }),
  });

  queryClient.setDefaultOptions({
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  });

  const getUserInfo = async () => {
    if (!getCookie("token")) {
      navigate(ROUTE_PATHS.LOGIN);
      return;
    }
    try {
      const userInfo = await getApiServices(apiPaths?.USER_BY_TOKEN);
      if (userInfo.status === 401) {
        removeCookie("token");
        removeCookie("roleId");
        navigate(ROUTE_PATHS.LOGIN);
      }
      const { data = null } = userInfo;
      dispatch(setUserInfo(data));
    } catch {}
  };

  useEffect(() => {
    getUserInfo();
  }, []); // eslint-disable-line

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={currentMode === "dark" ? darkTheme : theme}>
        {isLoading ? <CustomLoader /> : null}
        <Routes>
          {loginRoutes?.map((route) => (
            <Route
              path={route?.path}
              element={route?.element}
              key={route?.path}
            />
          ))}

          <Route path={ROUTE_PATHS.LAYOUT} element={<ProtectedRoute />}>
            {routes[userInfo?.role?.code ?? "super-admin"]?.map(
              ({ path, element, children = null }, index) =>
                routeMapping(path, element, children, index)
            )}
          </Route>

          {routes[userInfo?.role?.code ?? "super-admin"]?.length > 0 && (
            <Route path={ROUTE_PATHS?.NOT_FOUND} element={<NotFound />} />
          )}
        </Routes>
        {snackBar && <UserNotification />}
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default Root;
