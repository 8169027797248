import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useEffect, useMemo } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { ListWrapper, SideBarListContainer, SideBarWrapper } from "styles";
import { postApiServices } from "../../api/api";
import { apiPaths } from "../../api/apiPaths";
import { useCustomQuery } from "../../hooks/useCustomQuery";
import useTableCustomHooks from "../../hooks/useTableCustomHooks";
import { setIsLoading } from "../../redux/slice";
import { ROUTE_PATHS } from "../../routes/routePaths";
import { getTableSchemas } from "../../utils/tableSchemas";
import { CustomReactTable } from "./CustomReactTable";
import { ListTopBar } from "./ListTopBar";
import SideBarContainer from "./SideBarContainer";
import WithCondition from "./WithCondition";

const ListingSideBar = ({
  listPath,
  formPath,
  apiPath,
  columns,
  label,
  defaultSortedValues,
  disableTopBar,
  disableLayout,
  customApiPath,
  enableFilters,
  addFilters = [],
  additionalComponent,
  additionalTopBarPosition,
  refetchList,
  stopRefetch,
  addQueryData = {},
  disableSearch,
  disableFilter,
  disableFetch,
  navigateOnBack,
  newButtonLabel,
  SpacerPanel = <></>,
  renderSideBarItems: RenderSideBarItems,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const editId = searchParams.get("editId");
  const [sideBar, setSideBar] = useState({ isOpen: !!editId });
  const dispatch = useDispatch();
  const location = useLocation();
  const isAdmin = useSelector((state) => state?.isAdmin);
  const userInfo = useSelector((state) => state?.userInfo);
  const role = useSelector((state) => state?.role);
  const { searchFields, filterFields, filterInitialValues } = useMemo(
    () => getTableSchemas(columns),
    [columns]
  );
  const {
    onPageNumberChange,
    onChangePageSize,
    handleTableDatas,
    tableReRenderActions,
  } = useTableCustomHooks(ROUTE_PATHS?.[listPath]);

  const handleCloseSideBar = () => {
    const searchParam = new URLSearchParams(location.search);
    searchParam.delete("editId");

    navigate(`${ROUTE_PATHS?.[listPath]}?${searchParam.toString()}`, {
      state: location.state,
    });
    setSideBar((prev) => {
      return { isOpen: !prev?.isOpen };
    });
  };

  const listParams = useMemo(
    () => handleTableDatas(searchFields, columns, defaultSortedValues),
    [columns, defaultSortedValues, handleTableDatas, searchFields]
  );

  const filterByOrganization = () => {
    let filter = [];
    if (!isAdmin) {
      if (userInfo?.customerId) {
        // filter = [createFilter("organizationId", userInfo?.organizationId)];
        filter = [];
      }
    }
    return filter;
  };
  const branchFilter = enableFilters ? filterByOrganization() : [];
  const filters = [...listParams.filters, ...branchFilter, ...addFilters];

  const { pageSize, currentPage } = tableReRenderActions();

  const { data, isLoading, refetch } = useCustomQuery({
    queryKey: [
      "commonList" + apiPaths?.[apiPath],
      { ...listParams, filters, addQueryData },
    ],
    queryFn: () => {
      const path = customApiPath || apiPaths?.[apiPath];
      return postApiServices(path, {
        ...listParams,
        filters,
        ...addQueryData,
      });
    },
    enabled: (!!customApiPath || !!apiPaths?.[apiPath]) && !disableFetch,
    onSuccess: () => {
      if (refetchList) {
        dispatch(setIsLoading(false));
        stopRefetch && stopRefetch(false);
      }
    },
  });

  useEffect(() => {
    if (editId) setSideBar({ isOpen: true });
  }, [editId]);

  useEffect(() => {
    if (refetchList) refetch();
  }, [refetchList]); //eslint-disable-line

  return (
    <SideBarListContainer>
      <ListWrapper sx={{ width: sideBar?.isOpen ? "70%" : "100%" }}>
        <WithCondition isValid={!disableTopBar}>
          <ListTopBar
            label={label || ""}
            newFormPath={ROUTE_PATHS?.[formPath]}
            listPath={ROUTE_PATHS?.[listPath]}
            filterFields={filterFields}
            filterFieldInitial={filterInitialValues}
            showFilterApplied
            additionalComponent={additionalComponent}
            addPosition={additionalTopBarPosition}
            currentFilters={listParams.filters}
            disableSearchField={disableSearch}
            disableFilter={disableFilter}
            navigateOnBack={navigateOnBack}
            newButtonLabel={newButtonLabel}
            onAddPress={handleCloseSideBar}
            hasSideBarOpen
            isSideBarOpen={sideBar?.isOpen}
          />
        </WithCondition>
        <WithCondition isValid={SpacerPanel}>{SpacerPanel}</WithCondition>
        <CustomReactTable
          columnData={columns || []}
          rawData={data?.data?.rows || []}
          isLoading={isLoading}
          onPageNumberChange={onPageNumberChange}
          onChangePageSize={onChangePageSize}
          pageSize={pageSize}
          currentPage={currentPage}
          count={data?.data?.count}
          disableLayout={disableLayout}
        />
      </ListWrapper>
      <WithCondition isValid={sideBar?.isOpen}>
        <SideBarWrapper
          sx={{ width: "30%", marginRight: sideBar?.isOpen ? "0px" : "-900px" }}
        >
          <SideBarContainer header={label}>
            <RenderSideBarItems
              refetch={refetch}
              isOpen={sideBar?.isOpen}
              closeSideBar={handleCloseSideBar}
            />
          </SideBarContainer>
        </SideBarWrapper>
      </WithCondition>
    </SideBarListContainer>
  );
};

export default ListingSideBar;
