import { Box, styled } from "@mui/material";
import { theme } from "./theme";

export const MultiRecordTitle = styled("span")(({ theme }) => ({
  textTransform: "capitalize",
  marginRight: "20px",
  font: "normal normal 600 18px/17px Lato",
  color: `${theme?.palette.primary.main}`,
  fontSize: "19px",
  span: {
    fontWeight: "500",
    textTransform: "none",
    fontSize: "18px",
  },
}));

export const SubTitle = styled("span")(({ theme }) => ({
  marginRight: "20px",
  font: "normal normal 600 18px/17px Lato",
  color: `${theme?.palette.primary.main}`,
  fontSize: "16px",
  span: {
    fontWeight: "500",
    textTransform: "none",
    fontSize: "14px",
  },
}));

export const EllipsisDiv = styled("div")({
  display: "-webkit-box",
  "-webkit-line-clamp": "2",
  "-webkit-box-orient": "vertical",
  overflow: "hidden",
  textOverflow: "ellipsis",
  verticalAlign: "middle",
  wordWrap: "break-word",
});

export const CustomCell = ({ value, children }) => (
  <EllipsisDiv>{children || value || "--"}</EllipsisDiv>
);

export const PARAMETER_RADIO_LABEL = {
  color: theme.palette.primary.main,
  fontWeight: "400 !important",
};

export const ParameterLabel = styled("label")(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: "400",
  fontSize: "18px",
}));

export const MenuContainer = styled(Box)(({ theme, elementposition }) => ({
  position: "fixed",
  backgroundColor: theme.palette.background.default,
  borderRadius: "4px",
  padding: "8px 0px",
  boxShadow:
    "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
  top: elementposition?.x,
  left: elementposition?.y,
  zIndex: 13000,
}));

export const popoverItemStyle = {
  minWidth: 200,
  height: 50,
  display: "flex",
  alignItems: "center",
  padding: "0px 0.8rem",
  borderBottom: `1px solid ${theme.palette.grey?.[200]}`,
  cursor: "pointer",
};
export const TableContainer = styled(Box)({
  padding: "0 40px",
});
export const StyledCell = styled(Box)((props) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",

  div: {
    flex: "none !important",
    display: "-webkit-box",
    maxWidth: "80%",
    "-webkit-line-clamp": "2",
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    wordWrap: "break-word",
  },
  ...props?.style,
}));
