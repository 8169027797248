import {
  Box,
  FormControl,
  FormHelperText,
  IconButton,
  styled,
  useTheme,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";

import {
  AddPhotoAlternate,
  AudioFile,
  Cancel,
  UploadFile,
  VideoFile,
} from "@mui/icons-material";
import { PALETTE_KEYS } from "../../constants";
import useDispatchActions from "../../hooks/useDispatchActions";
import ScreenHelp from "./ScreenHelp";
import WithCondition from "./WithCondition";

const InputField = styled("input")(() => ({
  display: "none",
}));

const FileInputHolder = styled(Box)({
  display: "flex",
  alignItems: "center",
  padding: 10,
  border: "1px dashed #c4c4c4",
  height: "35px !important",
  borderRadius: 5,
  svg: { fontSize: "29px" },
});

const ImgTag = styled("img")(() => ({
  height: 40,
  width: 40,
  borderRadius: "50%",
  marginRight: 12,
}));
const FileName = styled("div")(({ theme }) => ({
  position: "absolute",
  top: "-10px",
  left: "15px",
  background: theme.palette.background.default,
  padding: "0 5px",
  fontSize: "13px",
  color: theme.palette.text.secondary,
}));
const FileLabel = styled("div")(({ theme }) => ({
  font: "normal normal normal 16px/19px Lato",
  width: "80%",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
}));

export const FileUpload = ({
  name,
  label,
  type,
  value,
  onChange,
  defaultLabel,
  setFieldValue,
  disabled,
  isMultirec,
  accept,
  touched,
  error,
  customHelpertext,
  disableResetForAllValues,
  sx,
  acceptType,
  help,
}) => {
  const [imgUrl, setImgUrl] = useState("");
  const [fileName, setFileName] = useState((value && value[0]?.name) || "");
  const myRefname = useRef(null);
  const [key, setKey] = useState(false);
  const { notifyError } = useDispatchActions();
  const theme = useTheme();

  const handleClick = (e) => {
    myRefname.current.click();
  };

  const onImageChange = (event) => {
    if (event?.target?.files?.[0]?.size > 50000000) {
      return notifyError("Size should be less than 10MB");
    } else if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e) => {
        if (type === "image" || type === "GIF") {
          setImgUrl(reader.result);
        }
        if (acceptType && !acceptType.includes(event.target.files[0].type)) {
          return notifyError("Media type not supported");
        }
        onChange(event);
        if (!isMultirec) {
          setFileName(event.target.files[0].name);
        }
      };
      if (
        !type ||
        event.target.files[0].type.includes(type === "gif" ? "image" : type)
      ) {
        reader.readAsDataURL(event.target.files[0]);
      } else {
        notifyError("Media type not supported");
      }
    }
  };

  useEffect(() => {
    if ((type === "image" || type === "GIF") && typeof value === "string") {
      setImgUrl(value);
    }
  }, [type, value]);

  useEffect(() => {
    if (!value && defaultLabel) {
      setImgUrl("");
      setFileName("");
    } else if (typeof value === "string") {
      setFileName(label);
    }
  }, [value]); // eslint-disable-line

  const errorBorder =
    error && touched
      ? {
          border: "1.8px dashed #d32f2f",
        }
      : {};

  const errorText =
    error && touched
      ? { color: theme.palette.error.main }
      : { color: theme.palette.grey[600] };

  const imageIcon = () => {
    if (type === "image" || type === "GIF") {
      return imgUrl ? (
        <ImgTag src={imgUrl} alt=""></ImgTag>
      ) : (
        <IconButton style={errorText}>
          <AddPhotoAlternate />
        </IconButton>
      );
    } else if (type === "audio") {
      return (
        <IconButton style={errorText}>
          <AudioFile />
        </IconButton>
      );
    } else if (type === "video") {
      return (
        <IconButton style={errorText}>
          <VideoFile />
        </IconButton>
      );
    } else {
      return (
        <IconButton style={errorText}>
          <UploadFile />
        </IconButton>
      );
    }
  };

  const resetValues = () => {
    if (type === "image") {
      setFieldValue(`${name}Url`, "");
    }
    setImgUrl("");
    setFieldValue(name, null);
    if (!disableResetForAllValues) {
      setFieldValue(`${name}Name`, "");
    }
    setKey(!key);
    setFileName("");
  };

  useEffect(() => {
    setKey(!key);
    if (value === "") {
      setFileName("");
    }
  }, [value]); // eslint-disable-line

  return (
    <>
      <FormControl fullWidth>
        <InputField
          ref={myRefname}
          name={name}
          key={key}
          type={"file"}
          onChange={(event) => onImageChange(event)}
          label={label}
          disabled={disabled}
          accept={type === "GIF" ? "image/*" : accept}
          error={Boolean(customHelpertext || (touched && error))}
        />

        <FileInputHolder
          className="label"
          sx={{
            padding: "27.5px 10px",
            ...errorBorder,
            cursor: disabled ? "auto" : "pointer",
            position: "relative",
            color: PALETTE_KEYS.text.primary,
            ...sx,
          }}
          onClick={(e) => handleClick(e)}
        >
          {fileName && <FileName>{defaultLabel}</FileName>}
          {imageIcon()}
          <FileLabel className="label" style={{ ...errorText }}>
            {fileName || defaultLabel || label}
          </FileLabel>
          {!disabled && value && (
            <IconButton
              sx={{ transform: "scale(.8)" }}
              onClick={(event) => {
                event.stopPropagation();
                resetValues();
              }}
            >
              <Cancel />
            </IconButton>
          )}
        </FileInputHolder>
        <FormHelperText error>
          {customHelpertext || (touched && error)}
        </FormHelperText>
      </FormControl>
      <WithCondition isValid={help}>
        <span>
          <ScreenHelp
            htmlText={help}
            isField
            buttonStyle={{ top: 0, bottom: -16 }}
          />
        </span>
      </WithCondition>
    </>
  );
};
